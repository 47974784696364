import * as React from "react";
import { TextField, Box, Button, Typography } from "@mui/material";
import { DEBUG } from "commons/preferences/config";
import { TOOL_PAGE_TITLE } from "tools/preferences/config";
import { searchResults } from "tools/api/api";

import LoadingPage from "commons/components/LoadingPage";
import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";

import Copyright from "commons/components/Copyright";
import SearchResult from "tools/components/SearchResult";

import { profileEncrypt } from "commons/utils/encrypt";
import Footer from "tools/layouts/Footer";

export default function SearchPage() {
  const [token, setToken] = React.useState({ email: "", pin: "" });
  const [loading, setLoading] = React.useState(false);
  const [searchFlag, setSearchFlag] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  const [results, setResults] = React.useState(null);

  async function handleSearch() {
    setLoading(true);

    const ecryptedProfile = profileEncrypt(token.email, token.pin);

    const resultsData = await searchResults(ecryptedProfile);

    if (resultsData.results) {
      if (DEBUG) console.log(resultsData);

      setResults(resultsData.results);
      setLoading(false);
      setSearchFlag(true);
    }
  }

  function handleChange(e) {
    const inputName = e.target.name;
    const inputValue = e.target.value.trim();

    let cleanValue;

    cleanValue = inputValue;

    const changedToken = {
      ...token,
      [inputName]: cleanValue,
    };

    setToken(changedToken);

    if (token.email && token.pin) {
      setButtonDisabled(false);
    }
  }

  if (loading) return <LoadingPage />;

  return (
    <div>
      <Header>{`${TOOL_PAGE_TITLE["SEARCH"]}`}</Header>
      <Content>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", my: "5rem" }}>
          {results && results.length > 0 ? (
            <SearchResult results={results} />
          ) : (
            searchFlag && (
              <Typography variant="text" sx={{ color: "#FF0000" }}>
                There is no results. Please check the email and pin number you entered when analyzing.
              </Typography>
            )
          )}
        </Box>

        <>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", my: "1rem" }}>
            <TextField
              sx={{ mb: "1rem", maxWidth: "20rem", minWidth: "20rem" }}
              type="email"
              name="email"
              label="Email"
              value={token.email}
              size="small"
              required
              helperText="이전 도구 사용 시 남겨 주셨던 email을 사용하세요."
              onChange={handleChange}
            />
            <TextField
              sx={{ mb: "1rem", maxWidth: "20rem", minWidth: "20rem" }}
              label="Pin"
              name="pin"
              type="password"
              value={token.pin}
              size="small"
              required
              helperText="입력했던 pin 번호를 사용하세요."
              onChange={handleChange}
            />
            <Box>
              <Button size="small" variant="contained" onClick={handleSearch} sx={{ m: "0.5rem" }} disabled={buttonDisabled}>
                Search
              </Button>
            </Box>
          </Box>
        </>
      </Content>
      <Footer>
        <Copyright />
      </Footer>
    </div>
  );
}
