import * as React from "react";

export default function MakeHeader({ row, hidden }) {
  return (
    <>
      <thead>
        {row && (
          <tr className="d-data-header">
            {row.map((data, index) => (
              <th key={index} className={hidden && hidden.includes(index) ? "d-data-th d-data-mobile-hidden" : "d-data-th"}>
                {data}
              </th>
            ))}
          </tr>
        )}
      </thead>
    </>
  );
}
