import * as React from "react";
import { Routes, Route } from "react-router-dom";

import ModulePage from "dashboards/pages/ModulePage";
import AnswersListPage from "dashboards/modules/status/AnswersListPage";
import ResultsListPage from "dashboards/modules/status/ResultsListPage";
import AnswerDetailPage from "dashboards/modules/status/AnswerDetailPage";
import ResultDetailPage from "dashboards/modules/status/ResultDetailPage";

export default function ModuleRouter() {
  return (
    <>
      <Routes>
        <Route path="status/answers/" element={<ModulePage><AnswersListPage /></ModulePage>} />
        <Route path="status/results/" element={<ModulePage><ResultsListPage /></ModulePage>} />
        <Route path="detail/answer/:uid/" element={<ModulePage ><AnswerDetailPage /></ModulePage>} />
        <Route path="detail/result/:uid/" element={<ModulePage ><ResultDetailPage /></ModulePage>} />
      </Routes>
    </>
  );
}
