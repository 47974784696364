import axios from "axios";
import { DEBUG, API_SERVER, API_URL_BASE } from "commons/preferences/config";
import { TOOL_API_URL } from "tools/preferences/config";
import { getCookie } from "commons/utils/cookies";

const csrftoken = getCookie("csrftoken");

const Axios = axios.create({
  baseURL: `${API_SERVER}${API_URL_BASE}`,
  headers: {
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken,
  },
  timeout: 5000,
});

export async function requestQuestionnaire(code) {
  try {
    const response = await Axios.get(`${TOOL_API_URL.QUESTIONNAIRE}/${code}/`);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function responseAnswers(data) {
  if (DEBUG) console.log(JSON.stringify(data));
  try {
    const response = await Axios.post(`${TOOL_API_URL.ANSWERS}/`, data);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function requestRawAnswers(uid) {
  if (DEBUG) console.log(JSON.stringify(uid));
  try {
    const response = await Axios.get(`${TOOL_API_URL.ANSWERS}/${uid}/`);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function requestResult(uid) {
  if (DEBUG) console.log(JSON.stringify(uid));
  try {
    const response = await Axios.get(`${TOOL_API_URL.RESULTS}/${uid}/`);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function requestBenchmarkValue(toolCode) {
  if (DEBUG) console.log(`${TOOL_API_URL.BENCHMARK}/${toolCode}/`);
  try {
    const response = await Axios.get(`${TOOL_API_URL.BENCHMARK}/${toolCode}/`);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function requestHtmlTemplate(templateCode) {
  if (DEBUG) console.log(JSON.stringify(templateCode));
  try {
    const response = await Axios.get(`${TOOL_API_URL.TEMPLATES_HTML}/${templateCode}/`);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function requestProgram(tool, phase) {
  try {
    const response = await Axios.get(`${TOOL_API_URL.PROGRAMS}/${tool}/${phase}/`);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function searchResults(encryptedProfile) {
  try {
    const response = await Axios.get(`${TOOL_API_URL.SEARCH_RESULTS}/${encryptedProfile}/`);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

// const Auth = axios.create({
//   baseURL: `${API_SERVER}/api/v1/users/auth/`,
//   headers: {
//     "Content-Type": "application/json",
//     "X-CSRFToken": csrftoken,
//   },
//   timeout: 5000,
// });

export async function requestPremiumPermisson(uniqueKey) {
  if (DEBUG) console.log(uniqueKey);
  try {
    const response = await Axios.get(`${TOOL_API_URL.PREMIUM_TOOLS_PERMISSON}/?uniqueKey=${uniqueKey}`);
    return response.data;
  } catch (error) {
    return error;
  }
}
