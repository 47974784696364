import * as React from "react";
import MakeRow from "dashboards/components/makeTable/MakeRow";
import MakeHeader from "dashboards/components/makeTable/MakeHeader";

export default function MakeTable({ header, rows, detail }) {
  const hidden = header && header.pop();

  return (
    <div className="d-data-wrapper">
      <table className="d-data-table">
        <MakeHeader row={header} hidden={hidden} />

        <tbody>{rows && rows.map((row, index) => <MakeRow key={index} row={row.rows} hidden={hidden} uid={row.uid} detail={detail} />)}</tbody>
      </table>
    </div>
  );
}
