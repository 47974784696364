import * as React from "react";
import { useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

export function useUniqueKeyInputBox(title, redirectTo) {
  const navigator = useNavigate();

  const [openFlagUniqueInput, setOpenFlagUniqueKeyInput] = React.useState(false);
  const [txtUniqueKey, setTxtUniqueKey] = React.useState();
  const [redirectToPremiumPath, setRedirectToPremiumPath] = React.useState(redirectTo);

  React.useEffect(() => setRedirectToPremiumPath(redirectTo), [redirectTo]);

  function hndUniqueKeyInputOpen(redirectTo) {
    setOpenFlagUniqueKeyInput(true);
  }

  function hndUniqueKeyInputClose() {
    setOpenFlagUniqueKeyInput(false);
  }

  function hndGoToPremium() {
    setOpenFlagUniqueKeyInput(false);
    if (txtUniqueKey) {
      navigator(`${redirectToPremiumPath}/?uniqueKey=${txtUniqueKey}`);
    }
  }

  function onChangeTxtUniqueKey(e) {
    if (txtUniqueKey !== e.target.value) setTxtUniqueKey(e.target.value);
  }

  const inputBox = (
    <>
      <Dialog open={openFlagUniqueInput} onClose={hndUniqueKeyInputClose}>
        {/* <DialogTitle>Unique Key for Premium</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            {title} 프리미엄 도구를 사용하기 위한 고유키를 입력하세요 <br />
            <br />
          </DialogContentText>
          <TextField autoFocus id="uniqueKey" name="uniqueKey" type="text" fullWidth variant="standard" onChange={onChangeTxtUniqueKey} />
        </DialogContent>
        <DialogActions>
          <button className="tool-page-button-explain" style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }} onClick={hndUniqueKeyInputClose}>
            취소
          </button>
          <button className="tool-page-button-tool" style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }} onClick={hndGoToPremium}>
            진단하기
          </button>
        </DialogActions>
      </Dialog>
    </>
  );

  return [inputBox, hndUniqueKeyInputOpen];
}
