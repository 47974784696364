import * as React from "react";

import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useRecoilValue } from "recoil";
import { toolState } from "tools/atoms/atoms";

import { useScrollTop } from "commons/hooks/general";

import * as CONFIG from "tools/preferences/config";
import useUniqueKey from "tools/hooks/useUniqueKey";

export function useQuestions(toolCode, nextPage, setRepaint) {
  const tools = useRecoilValue(toolState(toolCode));
  const [answer, setAnswer] = React.useState({});
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [questionItem, setQuestionItem] = React.useState([]);
  const navigator = useNavigate();
  const uniqueKey = useUniqueKey(toolCode);

  useScrollTop();
  React.useEffect(() => {
    setQuestionItem(tools.questions);
  }, [tools]);

  const totalQuestion = questionItem.length;

  const buttons = useQuestionPrevNextButton(
    toolCode,
    questionItem[questionIndex],
    questionIndex,
    setQuestionIndex,
    questionItem.length,
    answer,
    nextPage,
    setRepaint,
  );

  React.useEffect(() => {
    const uniqueKeyQuery = uniqueKey ? `/?uniqueKey=${uniqueKey}` : "";

    if (CONFIG.AUTO_TURNING_PAGE) {
      if (questionIndex < totalQuestion - 1) {
        setTimeout(() => {
          setQuestionIndex(() => questionIndex + 1);
        }, CONFIG.DELAY);
      } else if (questionIndex < totalQuestion) {
        setTimeout(() => {
          navigator(`${nextPage}${uniqueKeyQuery}`);
        }, CONFIG.DELAY);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  const isLoading = questionItem.length === 0;

  const progress = Math.round((questionIndex / totalQuestion) * 100);
  const progressLabel = `${questionIndex + 1}/${totalQuestion}`;

  return [
    isLoading,
    questionItem,
    questionIndex,
    progress,
    progressLabel,
    answer,
    setAnswer,
    buttons,
    tools.tool.option_scramble,
    tools.tool.use_question_adress,
  ];
}

function useQuestionPrevNextButton(toolCode, question, questionIndex, updater, upperLimit, answer, nextPage, setRepaint) {
  const [disabledNext, setDisabledNext] = React.useState(false);
  const [disabledPrev, setDisabledPrev] = React.useState(true);

  const navigator = useNavigate();

  const uniqueKey = useUniqueKey(toolCode);
  const uniqueKeyQuery = uniqueKey ? `/?uniqueKey=${uniqueKey}` : "";

  const handleNextButton = () => {
    setRepaint(true);
    updater(() => questionIndex + 1);
  };

  const handlePrevButton = () => {
    setRepaint(true);
    updater(() => questionIndex - 1);
  };

  React.useEffect(() => {
    if (questionIndex === 0) {
      setDisabledPrev(true);
    } else if (questionIndex > upperLimit - 2) {
      setDisabledNext(true);
    } else {
      setDisabledPrev(false);
      setDisabledNext(false);
    }
  }, [questionIndex, upperLimit]);

  React.useEffect(() => {
    if (question === undefined || question === null || answer[question.code] === undefined) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [question, answer]);

  const buttons = (
    <div className="div-center">
      <button
        className={disabledPrev ? "next-question-button-disabled" : "next-question-button"}
        onClick={handlePrevButton}
        disabled={disabledPrev ? "disabled" : ""}
      >
        <ArrowBackIosNewIcon />
      </button>
      {questionIndex < upperLimit - 1 ? (
        <button
          className={disabledNext ? "next-question-button-disabled" : "next-question-button"}
          onClick={handleNextButton}
          disabled={disabledNext ? "disabled" : ""}
        >
          <ArrowForwardIosIcon />
        </button>
      ) : (
        <button
          className={disabledNext ? "common-button-disabled" : "common-button"}
          onClick={() => navigator(`${nextPage}${uniqueKeyQuery}`)}
          disabled={disabledNext ? "disabled" : ""}
        >
          추가 질의
        </button>
      )}
    </div>
  );

  return buttons;
}
