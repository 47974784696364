import * as React from "react";
import { useParams } from "react-router-dom";
import { useFetchDetailData } from "dashboards/hooks/useFetchDetailData";
import { DASHBOARD_API_URL } from "dashboards/preferences/config";
import DetailViewer from "dashboards/components/DetailViewer";

export default function AnswerDetailPage() {
  const params = useParams();

  const detailData = useFetchDetailData(DASHBOARD_API_URL.ANSWER_DETAIL, params.uid, "", "");

  return <>{detailData && <DetailViewer detailData={detailData} mode="ANSWER" />}</>;
}
