import * as React from "react";
import { useNavigate } from "react-router-dom";
import { requestSidebars } from "dashboards/api/api";

import { DEBUG } from "commons/preferences/config";
import { DASHBOARD_PATH } from "dashboards/preferences/config";

export default function MakeSidebarSection() {
  const navigator = useNavigate();

  const [sidebars, setSidebars] = React.useState(null);

  React.useEffect(() => {
    async function fetchSidebars() {
      const data = await requestSidebars();

      if (data) setSidebars(data.results);
    }

    fetchSidebars();
  }, []);

  if (DEBUG) console.log(JSON.stringify(sidebars));

  return (
    <>
      {sidebars &&
        sidebars.map(
          (module, index_module) =>
            module.display && (
              <div key={`${index_module}_wrapper`} className="d-m-wrapper">
                <div key={`${index_module}_modules`} className="d-m-name">
                  {module.name}
                </div>
                {module.functions &&
                  module.functions.map(
                    (func, index_function) =>
                      func.display && (
                        <div
                          key={`${index_function}_functions`}
                          className="d-f-name"
                          onClick={() => navigator(`${DASHBOARD_PATH.BASE}${module.path}${func.path}/?module=${module.name}&func=${func.name}`)}
                        >
                          {func.name}
                        </div>
                      ),
                  )}
              </div>
            ),
        )}
    </>
  );
}
