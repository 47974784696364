import { getTimestamp } from "commons/utils/helpers";

export function postprocess(endpoint, answers, extras) {
  const added_timestamp_page = {
    ...extras.timestamp_page,
    result_page: getTimestamp(),
  };

  const changedExtras = {
    ...extras,
    timestamp_page: { ...added_timestamp_page },
  };

  const changedAnswers = {
    tool: endpoint,
    answerer: answers.profiles.email,
    ...answers,
    extras: changedExtras,
  };
  return changedAnswers;
}
