export const DASHBOARD_API_URL = {
  SIDEBAR_MENUS: "/dashboards/supports/sidebar-menus",
  TOOL_PHASE_SELECTOR: "/dashboards/supports/tool-phase-selector",

  TOOL_DATA: "/tools",

  ANSWERS_LIST: "/dashboards/status/answers",
  RESULTS_LIST: "/dashboards/status/results",

  ANSWER_DETAIL: "/dashboards/detail/answer",
  RESULT_DETAIL: "/dashboards/detail/result",
};


export const DASHBOARD_PATH = {
  BASE: "/dashboard",

  ANSWER_DETAIL: "/detail/answer",
  RESULT_DETAIL: "/detail/result",
}