import * as React from "react";
import { requestDetailData } from "dashboards/api/api";

export function useFetchDetailData(functions, uid, query, curPage) {
  const [datas, setDatas] = React.useState(null);

  React.useEffect(() => {
    async function fetchDetailData() {
      const data = await requestDetailData(functions, uid, query);

        setDatas(data);

    }

    fetchDetailData();
  }, [functions, uid, curPage, query]);

  return datas;
}
