import ConvertMark from "dashboards/components/ConvertMarks";

export function formattedFormValue(field, value) {
  switch (field) {
    case "total_occupation":
    case "now_occupation":
      return <div dangerouslySetInnerHTML={{__html: convertToYearMonth(value)}}></div>;
    case "agree":
    case "newsletter":
      return <ConvertMark value={value} size="32" />;
    default:
      return value;
  }
}


function convertToYearMonth(value){
    const [year, month] = divmod(value, 12)
    return `<span style="font-size:1.5rem; font-weight:bold;">${year}</span>년 ${month} 개월` ;
}

function divmod(value, moduler){
    const _value = parseInt(value);
    const _moduler = parseInt(moduler);
    const quotient = parseInt(_value / _moduler);
    const reminder = _value % _moduler;

    return [quotient, reminder]
}