/* eslint-disable react/jsx-no-target-blank */
import * as React from "react";

import { useNavigate } from "react-router-dom";
import { requestLogin } from "auth/api/api";
import { AUTH_TOKEN, HOST, AUTH_EXPIRED } from "commons/preferences/config";
import { HOME_URL } from "tools/preferences/config";

import { Container, Box, TextField } from "@mui/material";
import { localStorageGetItemWithExpiredTime, localStorageSetItemWithExpiredTime } from "commons/utils/localStorage";

export default function LoginPage({ endpoint }) {
  const navigator = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (localStorageGetItemWithExpiredTime(AUTH_TOKEN) !== null) {
      window.location.replace(`${HOST}/dashboard`);
    } else {
      setLoading(false);
    }
  }, [navigator, setLoading]);

  async function onSubmit(e) {
    e.preventDefault();

    const data = await requestLogin({
      email: email,
      password: password,
    });

    if (data.key) {
      localStorageSetItemWithExpiredTime(AUTH_TOKEN, data.key, AUTH_EXPIRED);
      window.location.replace(`${HOST}/dashboard`);
    } else {
      setEmail("");
      setPassword("");
      setErrors(true);
    }
  }

  return (
    <>
      <Container className="container">
        <Box className="login-box">
          {loading === false && <span className="text-size-lg text-color-accent">Login</span>}
          {errors === true && <span className="text-size-sm text-color-warning">Cannot log in with provided credentials</span>}
          {loading === false && (
            <>
              <TextField sx={{ mb: "1rem" }} type="email" label="Email" value={email} size="small" required onChange={(e) => setEmail(e.target.value)} />
              <TextField
                sx={{ mb: "1rem" }}
                label="Password"
                type="password"
                value={password}
                size="small"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <Box className="div-center">
                <button className="login-button" onClick={onSubmit}>
                  Login
                </button>
                <a href={HOST}>
                  <button className="login-button">Tools</button>
                </a>
              </Box>
            </>
          )}
        </Box>
        <Box className="div-center">
          <a target="_blank" href={HOME_URL} className="text-link">
            리더들의 퇴직 플래너 화담,하다
          </a>
        </Box>
      </Container>
    </>
  );
}
