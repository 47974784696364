import * as React from "react";
import { Container, Box } from "@mui/material";
import { HOST } from "commons/preferences/config";

export default function RedirectBox({ title, message, redirect_url = HOST, redirect_to = "화담,하다 Tools", copyright = true }) {
  const messages = message && message.split("<br>");
  return (
    <>
      {messages && (
        <>
          <Container className="container">
            <Box className="redirect-box">
              <Box className="redirect-title">{title}</Box>
              <Box className="area-divider"></Box>
              <Box className="area-divider-blank"></Box>
              {messages.map((msg, index) => (
                <Box key={index} className="redirect-message">
                  {msg}
                </Box>
              ))}
              <Box>
                <a href={redirect_url}>
                  <button className="common-button">{redirect_to}</button>
                </a>
              </Box>
            </Box>
          </Container>
        </>
      )}
    </>
  );
}
