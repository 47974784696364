import * as React from "react";
import { requestListData } from "dashboards/api/api";
import { parseListData } from "dashboards/utils/parseUrl";

export function useFetchListData(functions, query) {
    const [datas, setDatas] = React.useState(null);
  
    React.useEffect(() => {
      async function fetchListData() {
        const data = await requestListData(functions, query);
  
        if (data.results) {
          const parsedData = parseListData(data);
          setDatas(parsedData);
        } 
      }
  
      fetchListData();
    }, [functions, query]);
  
    return datas;
  }