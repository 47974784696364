import { DEBUG } from "commons/preferences/config";

export function shuffledArray(length, scramble = true) {
  const array = [...Array(length).keys()];

  if (!scramble) return array;

  return array.sort(() => Math.random() - 0.5);
}

export function isFirstItem(index) {
  return index === 0;
}

export function isLastItem(index, array) {
  return index === array.length - 1;
}

export function addItem(array, item, dup = true) {
  if (array === []) return [];

  array.push(item);

  if (dup) {
    return [...new Set(array)];
  } else {
    return [...array];
  }
}

export function removeItem(array, item) {
  if (array === []) return [];
  return array.filter((ele) => ele !== item);
}

export function toArray(str, deli = ",") {
  if (str === "" || str === null || str === undefined) return [];
  return str.split(deli).map((ele) => ele.trim());
}

export function toPlain(array, deli = ", ") {
  if (isEmpty(array)) return "";

  let str = "";
  array.forEach((ele, index) => {
    if (index < array.length - 1) {
      str = str + ele + deli;
    } else {
      str = str + ele;
    }
  });

  return str;
}

export function matchedKeys(objArray, key, condition, returnKey = "field") {
  return objArray.filter((ele) => ele[key] === condition).map((matchedEle) => matchedEle[returnKey]);
}

export function checkRequiredField(formConfig, formValues) {
  const requiredFields = matchedKeys(formConfig, "required", true);
  if (DEBUG) console.log("requiredFields: " + requiredFields);
  let checkFields = [];
  requiredFields.forEach((ele) => {
    if (DEBUG) console.log("field: " + ele + ", isNone: " + isNone(formValues[ele]));
    if (isNone(formValues[ele])) {
      const label = matchedKeys(formConfig, "field", ele, "label")[0];
      checkFields.push(label);
    }
  });

  const result = isEmpty(checkFields);
  return [result, checkFields];
}

export function isRequiredField(formConfig, field) {
  const obj = matchedKeys(formConfig, "field", field);
  if (DEBUG) console.log(field + ": " + JSON.stringify(obj));
  return obj.required === true;
}

export function validateValue(formConfig, formValues) {
  const emailFields = matchedKeys(formConfig, "type", "EMAIL");

  if (DEBUG) console.log("emailFields: " + emailFields);

  let checkFields = [];
  emailFields.forEach((ele) => {
    if (DEBUG) console.log("field: " + ele + ", isRequiredField: " + isRequiredField(formConfig, ele) + ", isValidEmail: " + isValidEmail(formValues[ele]));
    if (!isValidEmail(formValues[ele])) {
      const labels = matchedKeys(formConfig, "field", ele, "label");

      labels.forEach((item) => {
        checkFields.push(item);
      });
    }
  });

  const result = isEmpty(checkFields);
  return [result, checkFields];
}

export function isValidEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const validMailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (DEBUG) console.log("email: " + email);
  if (email !== null && email.match(validMailPattern)) {
    return true;
  } else {
    return false;
  }
}

export function isNone(value) {
  if (value === null || value === undefined || value.length === 0) {
    return true;
  } else {
    return false;
  }
}

export function isEmpty(array) {
  return array.length === 0;
}

export function setDefaultValues(formConfig, setter) {
  const defaultValues = {};
  formConfig.forEach((ele) => {
    defaultValues[ele.field] = ele.default;
  });

  setter(defaultValues);
}

export function getTimestamp() {
  const curr = new Date();

  // 2. UTC 시간 계산
  const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;

  // 3. UTC to KST (UTC + 9시간)
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const kr_curr = new Date(utc + KR_TIME_DIFF);

  return kr_curr;
}

