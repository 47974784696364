import { TRUE_CASE } from "commons/preferences/config";

export const AUTO_TURNING_PAGE = TRUE_CASE.includes(process.env.REACT_APP_AUTO_TURNING_PAGE.toUpperCase());
export const DELAY = parseInt(process.env.REACT_APP_DELAY);

export const PREMIUM_PERMISSION_CHECK = TRUE_CASE.includes(process.env.REACT_APP_PREMIUM_PERMISSION_CHECK.toUpperCase());
export const PREMIUM_PERMISSION_TOKEN = process.env.REACT_APP_PREMIUM_PERMISSION_TOKEN;

export const TOOL_TITLE = {
  TOOL_PAGE: "화담,하다 솔루션",

  REPLAT_PAGE: "Re:PLAT 퇴직 적응 진단 도구",

  RRI_PAGE: "Re:PLAT 퇴직 적응 진단 도구",

  PRE_RRI_LTE: "퇴직 준비도 진단 솔루션<br/>현직자용(Lite)", //퇴직준비 어떻게 시작해요?
  PRE_RRI_PRM: "퇴직 준비도 진단 솔루션<br/>현직자용(Premium)",
  PST_RRI_LTE: "퇴직 준비도 진단 솔루션<br/>퇴직자용(Lite)", //퇴직준비 되셨어요?
  PST_RRI_PRM: "퇴직 준비도 진단 솔루션<br/>퇴직자용(Premium)",

  NPT_LTE: "뉴업 진단 솔루션",

  COMPANY_PAGE: "기업용 진단 도구",

  CRC_STD: "CRC 퇴직문화 수준 진단<br/>(Lite)",
  CRC_EXT: "CRC 퇴직문화 수준 진단<br/>(Premium)",

  PMA_STD: "PMA 성과경영 성숙도 진단<br/>(Lite)",
  PMA_EXT: "PMA 성과경영 성숙도 진단<br/>(Premium)",

  RPS_PAGE: "기업용 프로그램 제안 도구",

  RPS_CLV: "퇴직 지원 프로그램 유형<br/>(사장/부사장급)",
  RPS_ELV: "퇴직 지원 프로그램 유형<br/>(상무/전무급)",
  RPS_SLV: "퇴직 지원 프로그램 유형<br/>(정년 퇴직 및 장기 근속 구성원)",
};

export const TOOL_PAGE_TITLE = {
  INTRO: "",
  QUESTION: "",
  ADDITIONAL: "",
  PROFILE: "",
  RESULT: "",
  SEARCH: "검색",

  REPLAT: "화담,하다 솔루션",
  RRI: "Re:PLAT 퇴직 준비도 진단",

  SCOCKPIT: "화담,하다 Biz 솔루션",
  RPS: "화담,하다 Biz 솔루션",
};

// QUESTION: "질의",
// ADDITIONAL: "추가 질의",
// PROFILE: "응답자 정보",
// RESULT: "결과",

export const TOOL_BASE_PATH = {
  ERROR: "/error",
  ERROR_NOT_FOUND: "/error/404",

  HOME: "",

  TOOLS: "/tools",

  REPLAT: "/replat",

  RRI: "/rri",
  PRE_RRI_LTE: "/rri/pre_lte",
  PRE_RRI_PRM: "/rri/pre_prm",
  PST_RRI_LTE: "/rri/pst_lte",
  PST_RRI_PRM: "/rri/pst_prm",


  NPT: "/npt",
  NPT_LTE: "/npt/lite",

  SCOCKPIT: "/s-cockpit",

  CRC: "/crc",
  CRC_STD: "/crc/lite",
  CRC_EXT: "/crc/premium",

  PMA: "/pma",
  PMA_STD: "/pma/lite",
  PMA_EXT: "/pma/premium",

  RPS: "/rps",
  RPS_CLV: "/rps/clevel",
  RPS_ELV: "/rps/elevel",
  RPS_SLV: "/rps/slevel",

  DASHBOARD: "/dashboard",
  AUTH: "/auth",

  SEARCH: "/search",

  EXTEND_CHECK: "/check",

  // 홈페이지 revision 후 삭제
  RRI_LTE: "/rri/lite",
  CRC_STD_OLD: "/crc/standard",
  PMA_STD_OLD: "/pma/standard",
};

export const PREMIUM_TOOL_CODE = ["PMA_EXT", "CRC_EXT", "PRE_RRI_PRM", "PST_RRI_PRM"];

export const TOOL_PAGE_PATH = {
  PREMIUM: "/premium",
  GUIDE: "/guide",
  INTRO: "/intro",
  QUESTION: "/question",
  ADDITIONAL: "/additional",
  PROFILE: "/profile",
  RESULT: "/result",
};

export const TOOL_API_URL = {
  QUESTIONNAIRE: "/tools/questionnaire",

  TEMPLATES_HTML: "/templates/html",

  ANSWERS: "/answers",
  RESULTS: "/results",
  BENCHMARK: "/results/benchmark",

  PROGRAMS: "/programs",

  SEARCH_RESULTS: "/searchs",

  PREMIUM_TOOLS_PERMISSON: "premium/permisson",
};

export const TEMPLATE_BREAK = "[[BREAK]]";

export const INTRO_PAGE_NEXT_BUTTON_PATH = {
  RRI_LTE: `${TOOL_BASE_PATH.RRI_LTE}${TOOL_PAGE_PATH.QUESTION}`,

  NPT_LTE: `${TOOL_BASE_PATH.NPT_LTE}${TOOL_PAGE_PATH.QUESTION}`,

  CRC_STD: `${TOOL_BASE_PATH.CRC_STD}${TOOL_PAGE_PATH.QUESTION}`,
  CRC_EXT: `${TOOL_BASE_PATH.CRC_EXT}${TOOL_PAGE_PATH.QUESTION}`,

  PMA_STD: `${TOOL_BASE_PATH.PMA_STD}${TOOL_PAGE_PATH.QUESTION}`,
  PMA_EXT: `${TOOL_BASE_PATH.PMA_EXT}${TOOL_PAGE_PATH.QUESTION}`,

  RPS_CLV: `${TOOL_BASE_PATH.RPS_CLV}${TOOL_PAGE_PATH.QUESTION}`,
  RPS_ELV: `${TOOL_BASE_PATH.RPS_ELV}${TOOL_PAGE_PATH.QUESTION}`,
  RPS_SLV: `${TOOL_BASE_PATH.RPS_SLV}${TOOL_PAGE_PATH.QUESTION}`,

  PRE_RRI_LTE: `${TOOL_BASE_PATH.PRE_RRI_LTE}${TOOL_PAGE_PATH.QUESTION}`,
  PRE_RRI_PRM: `${TOOL_BASE_PATH.PRE_RRI_PRM}${TOOL_PAGE_PATH.QUESTION}`,
  PST_RRI_LTE: `${TOOL_BASE_PATH.PST_RRI_LTE}${TOOL_PAGE_PATH.QUESTION}`,
  PST_RRI_PRM: `${TOOL_BASE_PATH.PST_RRI_PRM}${TOOL_PAGE_PATH.QUESTION}`,
};

export const HOME_URL = "https://www.whadam.co";
export const MORE_PROGRAMS_URL = "https://whadam.co/program";
