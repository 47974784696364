import * as React from "react";

import { useNavigate } from "react-router-dom";
import { DASHBOARD_PATH } from "dashboards/preferences/config";
import ConvertMark from "dashboards/components/ConvertMarks";
import { DEBUG } from "commons/preferences/config";

export default function MakeRow({ row, hidden, uid, detail }) {
  const navigator = useNavigate();

  function handleClick(uid) {
    navigator(`${DASHBOARD_PATH.BASE}${detail}/${uid}/`);
  }

  if (DEBUG) {
    console.log("ROWrow: " + JSON.stringify(row));
    console.log("uid: " + JSON.stringify(uid));
  }
  return (
    <>
      {row && (
        <tr className="d-data-row" onClick={() => handleClick(uid)}>
          {row.map((data, index) => (
            <td key={index} className={hidden && hidden.includes(index) ? "d-data-td d-data-mobile-hidden" : "d-data-td"}>
              <ConvertMark value={data} />
            </td>
          ))}
        </tr>
      )}
    </>
  );
}
