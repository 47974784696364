import * as React from "react";

import { useQueryParams } from "commons/hooks/general";
import { requestToolPhaseSelector } from "dashboards/api/api";
import { DEBUG } from "commons/preferences/config";

export default function MakeSearchSection({ action }) {
  const query = useQueryParams();

  const [toolPhaseSelector, setToolPhaseSelector] = React.useState(null);

  const [selectedTool, setSelectedTool] = React.useState(query.tool);
  const [selectedPhase, setSelectedPhase] = React.useState(query.phase);

  const [phases, setPhases] = React.useState(null);

  React.useEffect(() => {
    async function fetchToolPhaseSelector() {
      const data = await requestToolPhaseSelector();

      if (data) setToolPhaseSelector(data.results);
    }

    fetchToolPhaseSelector();
  }, [action]);

  React.useEffect(() => {
    if (toolPhaseSelector) {
      const phases = getPhasesWithToolCode(toolPhaseSelector, selectedTool);
      setPhases(phases);
    } else {
      setPhases([{ phase: "", phase_name: "결과 전체" }]);
    }
  }, [toolPhaseSelector, selectedTool]);

  function handleToolSelector(e) {
    setSelectedTool(e.target.value);
  }

  function handlePhaseSelector(e) {
    setSelectedPhase(e.target.value);
  }
  if (DEBUG) {
    console.log("selectedTool: " + selectedTool);
    console.log("phases: " + JSON.stringify(phases));
  }

  return (
    <>
      <form action={`/dashboard${action}/`} method="get">
        <div className="d-sec-data-search" style={{ marginBottom: "0.5rem" }}>
          <select className="select-data-search" id="tool-search" name="tool" value={selectedTool} onChange={handleToolSelector}>
            <option value={""}>도구 전체</option>
            {toolPhaseSelector &&
              toolPhaseSelector.map((tool, index) => (
                <option key={index} value={tool.code}>
                  {tool.name}
                </option>
              ))}
          </select>
          {action === "/status/results" ? (
            <select className="select-data-search" id="phase-search" name="phase" value={selectedPhase} onChange={handlePhaseSelector}>
              <option value={""}>결과 전체</option>
              {phases &&
                phases.map((phase, index) => (
                  <option key={index} value={phase.phase}>
                    {phase.phase_name}
                  </option>
                ))}
            </select>
          ) : (
            <>&nbsp;</>
          )}
          <input className="date-data-search" type="date" id="date-search-start" name="start" />
          <input className="date-data-search" type="date" id="date-search-end" name="end" />
          <input className="input-data-search" type="text" id="input-search" name="answerer" placeholder="이메일" />
          <input className="input-data-search" type="text" id="input-search" name="company" placeholder="회사" />
          <input className="display-hidden" type="text" name="module" defaultValue={query.module} />
          <input className="display-hidden" type="text" name="func" defaultValue={query.func} />
          <button className="btn-data-search" type="submit">
            Search{" "}
          </button>
        </div>
      </form>
    </>
  );
}

function getPhasesWithToolCode(toolPhaseSelector, toolCode) {
  for (const tool of toolPhaseSelector) {
    if (tool.code === toolCode) return tool.phases;
  }
}
