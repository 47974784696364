import * as React from "react";
import { Box } from "@mui/material";

import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";
import Footer from "tools/layouts/Footer";
import Copyright from "commons/components/Copyright";

import Question from "tools/components/Questions";

import { useQuestions } from "tools/hooks/useQuestions";
import LoadingPage from "commons/components/LoadingPage";
import ProgressBar from "tools/components/ProgressBar";
import { useSetExtraValue } from "tools/hooks/general";

import { TOOL_TITLE, TOOL_PAGE_TITLE } from "tools/preferences/config";
import { useScrollTop, usePreventBack, usePreventRefresh } from "commons/hooks/general";
import FixedQuestionButton from "tools/layouts/FixedQuestionButton";

export default function QuestionPage({ toolCode, nextPage }) {
  const [repaint, setRepaint] = React.useState(null);

  usePreventRefresh();
  usePreventBack();

  useSetExtraValue("timestamp_page", "question_page");
  const [isLoading, questionItem, questionIndex, progress, progressLabel, answer, setAnswer, buttons, scramble, question_adress] = useQuestions(
    toolCode,
    nextPage,
    setRepaint,
  );
  useScrollTop(repaint);
  if (isLoading) return <LoadingPage />;

  return (
    <>
      <Header>{`${TOOL_TITLE[toolCode]}  ${TOOL_PAGE_TITLE["QUESTION"]}`}</Header>
      <Content>
        <Box sx={{ mx: 1, mb: -1 }}>
          <ProgressBar label={progressLabel} value={progress} />
        </Box>
        {questionItem[questionIndex] && (
          <Question
            question={questionItem[questionIndex]}
            answer={answer}
            setAnswer={setAnswer}
            scramble={scramble}
            question_adress={question_adress}
            setRepaint={setRepaint}
          />
        )}
        <FixedQuestionButton>{buttons}</FixedQuestionButton>
      </Content>
      <Footer>
        <Copyright />
      </Footer>
    </>
  );
}
