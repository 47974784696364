import * as React from "react";
import { useNavigate } from "react-router-dom";

import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";

import { useScrollTop } from "commons/hooks/general";
import Copyright from "commons/components/Copyright";

import { TOOL_BASE_PATH, TOOL_PAGE_TITLE } from "tools/preferences/config";
import Footer from "tools/layouts/Footer";

export default function RePLATPage() {
  const navigator = useNavigate();

  useScrollTop();

  return (
    <>
      <Header>{`${TOOL_PAGE_TITLE["REPLAT"]}`}</Header>
      <Content>
        <div className="page_container div-center">
          <div className="page_headline page_center">Re:PLAT 퇴직 솔루션</div>
          <div className="tool-page-wrapper">
            <div className="tool-page-tool-wrapper">
              <div className="tool-page-tool-abbr">Re:PLAT - RRI</div>
              <div className="tool-page-tool-name">퇴직 준비도 진단</div>
              <div className="tool-page-tool-en">Retirement Readiness Index</div>
              <div className="tool-page-tool-phrase">퇴직준비 되셨어요? </div>
              <div className="tool-page-tool-desc">
                퇴직준비의 골든 타임은 언제나 바로 지금!
                <br />
                현직자와 퇴직자 누구나
                <br />
                나의 퇴직준비 단계를 진단해 볼 수 있어요.
              </div>
              <div className="tool-page-button-wrapper">
                <button className="tool-page-button-tool" onClick={() => navigator(TOOL_BASE_PATH.RRI)}>
                  진단하기
                </button>
                <button className="tool-page-button-explain" onClick={() => window.open("https://www.whadam.co/replat")}>
                  자세히 보기
                </button>
              </div>
            </div>

            <div className="tool-page-tool-wrapper">
              <div className="tool-page-tool-abbr">Re:PLAT - NPT</div>
              <div className="tool-page-tool-name">뉴업 진단</div>
              <div className="tool-page-tool-en">New-UP(業) Planning Tool</div>
              <div className="tool-page-tool-phrase">퇴직하고 뭐하세요?</div>
              <div className="tool-page-tool-desc">
                개인의 성향, 목표가치, 역량에 기반하여
                <br />
                퇴직 후 나에게 맞는
                <br />
                7가지 뉴업 New-UP(業) 솔루션을 제안합니다.
              </div>
              <div className="tool-page-button-wrapper">
                <button className="tool-page-button-tool" onClick={() => navigator(TOOL_BASE_PATH.NPT_LTE)}>
                  진단하기
                </button>
                <button className="tool-page-button-explain" onClick={() => window.open("https://www.whadam.co/replat")}>
                  자세히 보기
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <Footer>
        <Copyright />
      </Footer>
    </>
  );
}
