import * as React from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { makeQueryString } from "dashboards/utils/makeQuery";
import { useQueryParams } from "commons/hooks/general";

export default function MakePagination({ datas }) {
  const navigator = useNavigate();
  const location = useLocation();
  const query = useQueryParams();
  delete query.page;
  const queryString = makeQueryString(query);
  const pageLink = `${location.pathname}?${queryString}`;

  const prevPage = datas.curPage === 1 ? 1 : datas.prevPage;
  const nextPage = datas.curPage === datas.lastPage ? datas.lastPage : datas.nextPage;

  const displayPageNumber = 3;

  return (
    <>
      <div className="pagination-wrapper">
        <div className="pagination pagination-active pagination-arrow" onClick={() => navigator(`${pageLink}&page=1`)}>
          <span>◀◀</span>
        </div>
        <div className="pagination pagination-active pagination-arrow" onClick={() => navigator(`${pageLink}&page=${prevPage}`)}>
          <span>◀</span>
        </div>
        {datas.curPage - 1 > displayPageNumber && (
          <div className="pagination-omit">
            <span>●●●</span>
          </div>
        )}
        {[...Array(datas.lastPage).keys()]
          .map((key) => key + 1)
          .map(
            (page) =>
              page >= datas.curPage - displayPageNumber &&
              page <= datas.curPage + displayPageNumber && (
                <div
                  key={page}
                  className={datas.curPage === page ? "pagination pagination-disable" : "pagination pagination-active"}
                  onClick={() => navigator(`${pageLink}&page=${page}`)}
                >
                  {page}
                </div>
              ),
          )}
        {datas.lastPage - datas.curPage > displayPageNumber && (
          <div className="pagination-omit">
            <span>●●●</span>
          </div>
        )}
        <div className="pagination pagination-active pagination-arrow" onClick={() => navigator(`${pageLink}&page=${nextPage}`)}>
          <span>▶</span>
        </div>
        <div className="pagination pagination-active pagination-arrow" onClick={() => navigator(`${pageLink}&page=${datas.lastPage}`)}>
          <span>▶▶</span>
        </div>
      </div>
    </>
  );
}
