import * as React from "react";
import { Container, Box } from "@mui/material";
import { AUTH_TOKEN, HOST } from "commons/preferences/config";
import { localStorageGetItemWithExpiredTime, localStorageRemoveItem } from "commons/utils/localStorage";

export default function LogoutPage() {
  const [authToken, setAuthToken] = React.useState(null);

  React.useEffect(() => {
    const _authoTken = localStorageGetItemWithExpiredTime(AUTH_TOKEN);

    setAuthToken(_authoTken);
    console.log(_authoTken);
  }, []);

  async function handleReset(e) {
    e.preventDefault();

    localStorageRemoveItem(AUTH_TOKEN);
    window.location.replace(`${HOST}`);
  }

  return (
    <div>
      <Container className="container">
        <Box className="login-box">
          <span>token: {authToken}</span>
          <input type="button" value="Reset" onClick={handleReset} />
        </Box>
      </Container>
    </div>
  );
}
