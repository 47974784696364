import * as React from "react";
import { Routes, Route } from "react-router-dom";

import PremiumIntroPage from "tools/pages/PremiumIntroPage";
import IntroPage from "tools/pages/IntroPage";
import QuestionPage from "tools/pages/QuestionPage";
import ProfilePage from "tools/pages/ProfilePage";
import AdditionalPage from "tools/pages/AdditionalPage";
import ResultPage from "tools/pages/ResultPage";
import RPSPage from "tools/pages_static/RPSPage";
import RRIPage from "tools/pages_static/RRIPage";
import { TOOL_BASE_PATH, PREMIUM_PERMISSION_CHECK, TOOL_PAGE_PATH } from "tools/preferences/config";
import { DEBUG } from "commons/preferences/config";
import { PREMIUM_TOOL_CODE } from "tools/preferences/config";

export default function SubRouterPage({ toolCode }) {
  const TOOL_PATH = TOOL_BASE_PATH[toolCode];

  if (DEBUG) console.log(toolCode);
  return (
    <>
      <Routes>
        {toolCode === "RPS" && (
          <>
            <Route path={`/`} element={<RPSPage toolCode={toolCode} />} />
          </>
        )}

        {toolCode === "RRI" && (
          <>
            <Route path={`/`} element={<RRIPage toolCode={toolCode} />} />
          </>
        )}

        {PREMIUM_TOOL_CODE.includes(toolCode) && PREMIUM_PERMISSION_CHECK ? (
          <>
            <Route path={`/`} element={<PremiumIntroPage toolCode={toolCode} nextPage={`${TOOL_PATH}${TOOL_PAGE_PATH.INTRO}/`} />} />
            <Route path={`${TOOL_PAGE_PATH.EXTEND}/`} element={<PremiumIntroPage toolCode={toolCode} nextPage={`${TOOL_PATH}${TOOL_PAGE_PATH.INTRO}/`} />} />
          </>
        ) : (
          <>
            <Route path={`/`} element={<IntroPage toolCode={toolCode} nextPage={`${TOOL_PATH}${TOOL_PAGE_PATH.QUESTION}/`} />} />
            <Route path={`${TOOL_PAGE_PATH.INTRO}/`} element={<IntroPage toolCode={toolCode} nextPage={`${TOOL_PATH}${TOOL_PAGE_PATH.QUESTION}/`} />} />
          </>
        )}
        <Route path={`${TOOL_PAGE_PATH.QUESTION}/`} element={<QuestionPage toolCode={toolCode} nextPage={`${TOOL_PATH}${TOOL_PAGE_PATH.ADDITIONAL}/`} />} />
        <Route
          path={`${TOOL_PAGE_PATH.ADDITIONAL}/`}
          element={<AdditionalPage toolCode={toolCode} formSegment="additionals" nextPage={`${TOOL_PATH}${TOOL_PAGE_PATH.PROFILE}/`} />}
        />
        <Route
          path={`${TOOL_PAGE_PATH.PROFILE}/`}
          element={<ProfilePage toolCode={toolCode} formSegment="profiles" nextPage={`${TOOL_PATH}${TOOL_PAGE_PATH.RESULT}/`} />}
        />
        <Route path={`${TOOL_PAGE_PATH.RESULT}/:uid/`} element={<ResultPage toolCode={toolCode} />} />
      </Routes>
    </>
  );
}
