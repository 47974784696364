import * as React from 'react';

export default function TopScroll() {

    const [showButton, setShowButton] = React.useState(false);

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        })

    }
    React.useEffect(() => {
        const handleShowButton = () => {
            if (window.scrollY > 50) {
                setShowButton(true)
            } else {
                setShowButton(false)
            }
        }

        // console.log(window.scrollY)
        window.addEventListener("scroll", handleShowButton)
        return () => {
            window.removeEventListener("scroll", handleShowButton)
        }
    }, [])

    return showButton && (
        <div className="top-scroll-button-wrapper">
            <button className="top-scroll-button" id="top" onClick={scrollToTop} type="button" >▲</button>
        </div>

    )
}