import * as React from "react";

import { useLocation } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Box, Divider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { toPlain } from "commons/utils/helpers";
import * as CONFIG from "tools/preferences/config";

export function useWindowSize() {
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const resize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useLayoutEffect(() => {
    window.addEventListener("resize", resize);
    resize();
    return () => window.removeEventListener("resize", resize);
  }, []);
  return [width, height];
}

export function useMousePosition() {
  const [x, setX] = React.useState(0);
  const [y, setY] = React.useState(0);
  const setPosition = ({ x, y }) => {
    setX(x);
    setY(y);
  };
  React.useLayoutEffect(() => {
    window.addEventListener("mousemove", setPosition);
    return () => window.removeEventListener("mousemove", setPosition);
  }, []);
  return [x, y];
}

export function useAlertDialog() {
  const [open, setOpen] = React.useState(false);
  const [contents, setContents] = React.useState({ title: "Alert", alertList: ["Something"], message: " is happend..." });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return [
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle className="div-center">{contents?.title}</DialogTitle>
      <Divider sx={{ mx: 3 }} />
      <DialogContent>
        <DialogContentText>
          <Box component="span" className="warning-message">
            {toPlain(contents.alertList)}
          </Box>
          <Box component="span">{contents.message}</Box>
        </DialogContentText>
      </DialogContent>
      <Divider sx={{ mx: 3 }} />
      <DialogActions sx={{ justifyContent: "center" }}>
        <button className="common-button" sx={{ pr: 3 }} onClick={handleClose} autoFocus>
          확인
        </button>
      </DialogActions>
    </Dialog>,
    handleOpen,
    setContents,
  ];
}

export function useScrollTop(repaint) {
  console.dir("repaint: " + JSON.stringify(repaint));
  React.useEffect(() => {
    if (repaint === true || repaint === undefined || repaint === null) {
      setTimeout(() => window.scrollTo(0, 0), CONFIG.DELAY);
    }
  }, [repaint]);
}

export function usePreventRefresh() {
  function preventClose(e) {
    e.preventDefault();
    e.returnValue = "";
  }

  React.useEffect(() => {
    window.addEventListener("beforeunload", preventClose);

    return () => {
      window.removeEventListener("beforeunload", preventClose);
    };
  }, []);
}

export function usePreventBack() {
  function preventGoBack() {
    window.history.pushState(null, "", window.location.href);
  }

  React.useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", preventGoBack);

    return () => {
      window.removeEventListener("popstate", preventGoBack);
    };
  }, []);
}

export function useQueryParams() {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // return React.useMemo(() => new URLSearchParams(search), [search]);
  return params;
}
