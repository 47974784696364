import * as React from "react";
import { AUTH_TOKEN } from "commons/preferences/config";
import { requestIsAuthenticated } from "auth/api/api";
import { localStorageGetItemWithExpiredTime } from "commons/utils/localStorage";

export function useIsAuthenticated(navigator) {
  const [isAuthenticated, setAuthenticated] = React.useState(undefined);
  React.useLayoutEffect(() => {
    async function isLoggedIn() {
      if (localStorageGetItemWithExpiredTime(AUTH_TOKEN) !== null) {
        const data = await requestIsAuthenticated(`Token ${localStorageGetItemWithExpiredTime(AUTH_TOKEN)}`);

        if (data) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
      } else {
        setAuthenticated(false);
      }
    }
    isLoggedIn();
  }, [navigator]);

  return isAuthenticated;
}

export function useAuthenticatedUserInfo(isAuthenticated) {
  const [userInfo, setUserInfo] = React.useState({});
  React.useEffect(() => {
    async function fetchUserInfo() {
      if (isAuthenticated) {
        const data = await requestIsAuthenticated(`Token ${localStorageGetItemWithExpiredTime(AUTH_TOKEN)}`);
        setUserInfo(data);
      }
    }
    fetchUserInfo();
  }, [isAuthenticated]);

  return userInfo;
}
