import axios from "axios";
import { API_SERVER, API_URL_BASE, DEBUG } from "commons/preferences/config";
import { getCookie } from "commons/utils/cookies";

import { DASHBOARD_API_URL } from "dashboards/preferences/config";

const csrftoken = getCookie("csrftoken");

const Axios = axios.create({
  baseURL: `${API_SERVER}${API_URL_BASE}`,
  headers: {
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken,
  },
  timeout: 5000,
});

export async function requestSidebars() {
  try {
    const response = await Axios.get(`${DASHBOARD_API_URL.SIDEBAR_MENUS}/`);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function requestListData(function_path, query) {
  const path = `${function_path}/?${query}`;

  if (DEBUG) console.log(path);
  try {
    const response = await Axios.get(path);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function requestToolPhaseSelector() {
  try {
    const response = await Axios.get(`${DASHBOARD_API_URL.TOOL_PHASE_SELECTOR}/`);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function requestDetailData(function_path, uid, query) {
  const path = `${function_path}/${uid}/?${query}`;

  if (DEBUG)  console.log(path);
  try {
    const response = await Axios.get(path);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function requestToolData(toolCode) {
  try {
    const response = await Axios.get(`${DASHBOARD_API_URL.TOOL_DATA}/${toolCode}/`);
    // const status = response.status;
    return response.data;
  } catch (error) {
    return error;
  }
}
