import * as React from "react";

import { Box } from "@mui/material";

import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";

import LoadingPage from "commons/components/LoadingPage";
import Copyright from "commons/components/Copyright";

import { useSetExtraValue, useRequestTemplate } from "tools/hooks/general";
import { useScrollTop } from "commons/hooks/general";

import { useRecoilState } from "recoil";
import { toolState } from "tools/atoms/atoms";
import { PREMIUM_PERMISSION_TOKEN, TOOL_TITLE, TOOL_PAGE_TITLE } from "tools/preferences/config";
import IntroPageButton from "tools/components/IntroPageButton";
import { localStorageRemoveItem } from "commons/utils/localStorage";
import Footer from "tools/layouts/Footer";

export default function IntroPage({ toolCode }) {
  const [loading, template] = useRequestTemplate(`${toolCode}_INTRO`);

  localStorageRemoveItem(PREMIUM_PERMISSION_TOKEN);

  useScrollTop();
  useRecoilState(toolState(toolCode));

  useSetExtraValue("timestamp_page", "intro_page");

  if (loading) return <LoadingPage />;

  return (
    <>
      <Header>{`${TOOL_TITLE[toolCode]} ${TOOL_PAGE_TITLE["INTRO"]}`}</Header>
      <Content>
        <Box dangerouslySetInnerHTML={{ __html: template[0] }} />
        <IntroPageButton toolCode={toolCode} />
        {template[1] && <Box dangerouslySetInnerHTML={{ __html: template[1] }} />}
      </Content>
      <Footer>
        <Copyright />
      </Footer>
    </>
  );
}
