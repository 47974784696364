export function setCookie(keyName, keyValue) {
  document.cookie = `${keyName}=${keyValue}`;
}

export function getCookie(keyName) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, keyName.length + 1) === keyName + "=") {
        cookieValue = decodeURIComponent(cookie.substring(keyName.length + 1));
        break;
      }
    }
  }

  return cookieValue;
}
