import TopScroll from "commons/components/TopScroll";
import * as React from "react";

export default function Main({ children }) {
  return (
    <>
      <div className="dl-module-main">
        <div className="d-content">{children}</div>
        <TopScroll/>
      </div>
    </>
  );
}
