import axios from "axios";
import { DEBUG, API_SERVER, API_URL_BASE } from "commons/preferences/config";
import { AUTH_API_URL } from "auth/preferences/config";
import { getCookie } from "commons/utils/cookies";

const csrftoken = getCookie("csrftoken");

const Axios = axios.create({
  baseURL: `${API_SERVER}${API_URL_BASE}`,
  headers: {
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken,
  },
  timeout: 5000,
});

export async function requestLogin(account) {
  if (DEBUG) console.log(JSON.stringify(account));
  if (DEBUG) console.log("Login");
  try {
    const response = await Axios.post(`${AUTH_API_URL.AUTH_LOGIN}/`, account);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function requestLogout(token) {
  if (DEBUG) console.log("Logout");
  Axios.defaults.headers.common["Authorization"] = token;
  try {
    const response = await Axios.post(`${AUTH_API_URL.AUTH_LOGOUT}/`);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function requestIsAuthenticated(token) {
  if (DEBUG) console.log("IsAuthenticated");
  Axios.defaults.headers.common["Authorization"] = token;
  try {
    const response = await Axios.get(`${AUTH_API_URL.AUTH_USERS}/`);
    return response.data;
  } catch (error) {
    return error;
  }
}
