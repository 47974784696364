import * as React from "react";

import { useNavigate } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { toolState } from "tools/atoms/atoms";
import { Box } from "@mui/material";

import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";

import LoadingPage from "commons/components/LoadingPage";
import { isEmpty } from "commons/utils/helpers";
import { useSetExtraValue } from "tools/hooks/general";
import { useFormPage } from "tools/hooks/useForms";
import { TOOL_TITLE, TOOL_PAGE_TITLE } from "tools/preferences/config";
import { PREMIUM_PERMISSION_TOKEN } from "tools/preferences/config";
import Copyright from "commons/components/Copyright";
import { useScrollTop, usePreventBack, usePreventRefresh } from "commons/hooks/general";
import { localStorageGetItemWithExpiredTime } from "commons/utils/localStorage";
import useUniqueKey from "tools/hooks/useUniqueKey";
import Footer from "tools/layouts/Footer";

export default function ProfilePage({ toolCode, formSegment, nextPage }) {
  const navigator = useNavigate();
  const tools = useRecoilValue(toolState(toolCode));
  const [premiumUserCompany, setPremiumUserCompany] = React.useState(null);

  const uniqueKey = useUniqueKey(toolCode);
  const uniqueKeyQuery = uniqueKey ? `/?uniqueKey=${uniqueKey}` : "";

  usePreventRefresh();
  usePreventBack();

  const formConfig = tools?.[formSegment] || [];
  useSetExtraValue("timestamp_page", "additional_page");

  const [forms, alertDialog, formValidate] = useFormPage(formSegment, formConfig);

  React.useEffect(() => {
    const _premiumUserCompany = localStorageGetItemWithExpiredTime(PREMIUM_PERMISSION_TOKEN);
    if (_premiumUserCompany) {
      setPremiumUserCompany(_premiumUserCompany);
    }
  }, []);

  function handleClickNext() {
    if (formValidate()) {
      navigator(`${nextPage}${uniqueKeyQuery}`);
    }
  }

  useScrollTop();

  if (isEmpty(formConfig)) return <LoadingPage />;

  return (
    <>
      <Header>{`${TOOL_TITLE[toolCode]}  ${TOOL_PAGE_TITLE["ADDITIONAL"]}`}</Header>
      <Content>
        {premiumUserCompany && <div style={{ display: "none" }}>{JSON.stringify(premiumUserCompany)}</div>}
        {forms}
        <Box sx={{ mt: 5, mb: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          <button className="common-button" onClick={handleClickNext}>
            확인
          </button>
        </Box>
      </Content>
      <Footer>
        <Copyright />
      </Footer>
      {alertDialog}
    </>
  );
}
