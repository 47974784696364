import * as React from "react";
import { Routes, Route } from "react-router-dom";

import MainPage from "dashboards/pages/MainPage";
import ModuleRouter from "dashboards/routers/ModuleRouters";
import { useNavigate } from "react-router-dom";
import RedirectBox from "commons/components/RedirectBox";
import { TOOL_BASE_PATH } from "tools/preferences/config";
import AnswersListPage from "dashboards/modules/status/AnswersListPage";
import { useIsAuthenticated } from "auth/hooks/useAuth";

export default function Router() {
  const navigator = useNavigate();

  const isAuthenticated = useIsAuthenticated(navigator);

  if (isAuthenticated === undefined) return null;

  return (
    <>
      {isAuthenticated ? (
        <Routes>
          <Route path="" element={<MainPage><AnswersListPage/></MainPage>} />
          <Route path="/*" element={<ModuleRouter />} />
        </Routes>
      ) : (
        <RedirectBox title="관리자 페이지" message="로그인 해야하는 페이지입니다" redirect_url={TOOL_BASE_PATH.AUTH} redirect_to="Login" />
      )}
    </>
  );
}
