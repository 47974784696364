import * as React from "react";
import { Box } from "@mui/material";

export default function Divider({ lineColor = "#441468", bgColor = "#441468", thickness = "5px", children = null, sx }) {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
        <Box sx={{ backgroundColor: lineColor, height: thickness, flexGrow: 1 }}></Box>
        {children && (
          <>
            <Box sx={{ mx: "0.5rem", p: "0.5rem", px: "2rem", backgroundColor: bgColor, minWidth: "10rem", textAlign: "center", borderRadius: "50px" }}>
              {children}
            </Box>
            <Box sx={{ backgroundColor: lineColor, height: thickness, flexGrow: 1 }}></Box>
          </>
        )}
      </Box>
    </>
  );
}
