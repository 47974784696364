import { createTheme } from "@mui/material/styles";


export let mainTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: '#87037B',
      text: "white",
    },
  },

  typography: {
    fontFamily: "Iropke Batang",
  },
});
