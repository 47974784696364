import * as React from "react";

import { Box, Typography, LinearProgress } from "@mui/material";

export default function ProgressBar({ label = "", value = 0 }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: -0.9, mb: -1, justifyContent: "space-between" }}>
      <Box sx={{ width: "100%", color: "primary.main" }}>
        <LinearProgress sx={{ borderRadius: 15, height: "0.5rem", bgcolor: "lightgrey" }} color="inherit" variant="determinate" value={value} />
      </Box>
      {label !== "" && (
        <Box sx={{ ml: 1, minWidth: "1rem", display: "flex", justifyContent: "flex-end" }}>
          <Typography sx={{ fontSize: "1rem", color: "background.text" }}>{`${label}`}</Typography>
        </Box>
      )}
    </Box>
  );
}
