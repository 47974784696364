import * as React from "react";

export default function HeaderTitle({ text }) {
  const [firstLine, secondLine] = text.split("<br/>");

  return (
    <>
      <div className="layout-header-title-text">
        <div className="layout-header-title-text-first">{firstLine}</div>
        <div className="layout-header-title-text-second">{secondLine}</div>
      </div>
    </>
  );
}
