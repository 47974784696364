import * as React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { TOOL_BASE_PATH } from "tools/preferences/config";

import SubRouterPage from "tools/routers/SubRouterPage";

import SearchPage from "tools/pages/SearchPage";

import ToolsPage from "tools/pages_static/ToolsPage";
import RePLATPage from "tools/pages_static/RePLATPage";
import SCockpitPage from "tools/pages_static/SCockpitPage";

import AuthRouterPage from "auth/Routers";
import DashboardRouterPage from "dashboards/routers/MainRouters";

import RedirectBox from "commons/components/RedirectBox";

function Routers() {
  return (
    <>
      <Routes>
        <Route path={`${TOOL_BASE_PATH.AUTH}/*`} element={<AuthRouterPage />} />
        <Route path={`${TOOL_BASE_PATH.DASHBOARD}/*`} element={<DashboardRouterPage />} />

        <Route path="/" element={<Navigate to="tools" />} />
        <Route path="tools" element={<ToolsPage />} />

        <Route path={`${TOOL_BASE_PATH.REPLAT}/`} element={<RePLATPage />} />

        <Route path={`${TOOL_BASE_PATH.RRI}/*`} element={<SubRouterPage toolCode={"RRI"} />} />
        <Route path={`${TOOL_BASE_PATH.PRE_RRI_LTE}/*`} element={<SubRouterPage toolCode={"PRE_RRI_LTE"} />} />
        <Route path={`${TOOL_BASE_PATH.PRE_RRI_PRM}/*`} element={<SubRouterPage toolCode={"PRE_RRI_PRM"} />} />
        <Route path={`${TOOL_BASE_PATH.PST_RRI_LTE}/*`} element={<SubRouterPage toolCode={"PST_RRI_LTE"} />} />
        <Route path={`${TOOL_BASE_PATH.PST_RRI_PRM}/*`} element={<SubRouterPage toolCode={"PST_RRI_PRM"} />} />

        <Route path={`${TOOL_BASE_PATH.NPT_LTE}/*`} element={<SubRouterPage toolCode={"NPT_LTE"} />} />

        <Route path={`${TOOL_BASE_PATH.SCOCKPIT}/`} element={<SCockpitPage />} />

        <Route path={`${TOOL_BASE_PATH.CRC_STD}/*`} element={<SubRouterPage toolCode={"CRC_STD"} />} />
        <Route path={`${TOOL_BASE_PATH.CRC_EXT}/*`} element={<SubRouterPage toolCode={"CRC_EXT"} />} />

        <Route path={`${TOOL_BASE_PATH.PMA_STD}/*`} element={<SubRouterPage toolCode={"PMA_STD"} />} />
        <Route path={`${TOOL_BASE_PATH.PMA_EXT}/*`} element={<SubRouterPage toolCode={"PMA_EXT"} />} />

        <Route path={`${TOOL_BASE_PATH.RPS}/*`} element={<SubRouterPage toolCode={"RPS"} />} />
        <Route path={`${TOOL_BASE_PATH.RPS_CLV}/*`} element={<SubRouterPage toolCode={"RPS_CLV"} />} />
        <Route path={`${TOOL_BASE_PATH.RPS_ELV}/*`} element={<SubRouterPage toolCode={"RPS_ELV"} />} />
        <Route path={`${TOOL_BASE_PATH.RPS_SLV}/*`} element={<SubRouterPage toolCode={"RPS_SLV"} />} />

        <Route path={`${TOOL_BASE_PATH.SEARCH}/`} element={<SearchPage />} />

        <Route path="*" element={<RedirectBox title="404 Not Found" message="페이지 주소를 확인해주세요." />} />

        {/* 홈페이지 revision 후 삭제 */}
        <Route path={`${TOOL_BASE_PATH.RRI_LTE}`} element={<Navigate to={TOOL_BASE_PATH.PST_RRI_LTE} />} />
        <Route path={`${TOOL_BASE_PATH.CRC_STD_OLD}`} element={<Navigate to={TOOL_BASE_PATH.CRC_STD} />} />
        <Route path={`${TOOL_BASE_PATH.PMA_STD_OLD}`} element={<Navigate to={TOOL_BASE_PATH.PMA_STD} />} />
      </Routes>
    </>
  );
}

export default Routers;
