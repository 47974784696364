export function parseListData(datas) {
  const list = datas.results;

  return {
    totalItems: datas.total_items,
    itemsPerPage: datas.items_per_page,
    itemsCurPage: list.length,
    nextPage: datas.next_page,
    prevPage: datas.prev_page,
    curPage:datas.cur_page,
    lastPage: datas.last_page,
    list: list,
  };
}

export function parseKeyFromUrlString(urlString, key) {
  if (urlString === null) return null;
  const url = new URL(urlString);
  return url.searchParams.get(key);
}

export function isPage(value) {
    if (value === null || value === undefined || parseInt(value) <= 0) {
      return 1;
    } else {
      return value;
    }
  }
  