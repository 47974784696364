import * as React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, FormLabel, Radio, RadioGroup, FormControlLabel, Checkbox, FormGroup } from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";

import * as FORMSTYLE from "commons/preferences/styles";

import LoadingPage from "commons/components/LoadingPage";

import { addItem, removeItem, toArray, setDefaultValues } from "commons/utils/helpers";

export function Forms(formConfig) {
  const [values, setValues] = React.useState({});
  const [inputs, setInputs] = React.useState([]);

  React.useEffect(() => {
    setDefaultValues(formConfig, setValues);
  }, [formConfig]);

  const handleChanged = ({ target: { name, value } }) => {
    const changedInputs = {
      ...inputs,
      [name]: value,
    };

    setInputs(changedInputs);
    setValues({
      ...values,
      ...changedInputs,
    });
  };

  const [checkedList, setCheckedList] = React.useState({});
  const handleChecked = ({ target: { name, checked } }) => {
    const changedCheckedList = {
      ...checkedList,
      [name]: checked,
    };
    setCheckedList(changedCheckedList);
    setValues({
      ...values,
      ...changedCheckedList,
    });
  };

  const [multipleCheckedList, setMultipleCheckedList] = React.useState({});
  const handleMultipleChecked = ({ target: { name, value, checked } }) => {
    let changedMultipleCheckedList = {};

    const currentCheckedListArray = toArray(multipleCheckedList?.[name] || "");

    // console.log("name: " + name + ", value: " + value + ", checked: " + checked);
    // console.log("currentCheckedListArray: " + currentCheckedListArray);

    if (checked) {
      changedMultipleCheckedList = {
        ...multipleCheckedList,
        [name]: addItem(currentCheckedListArray, value).join("|"),
      };
    } else {
      changedMultipleCheckedList = {
        ...multipleCheckedList,
        [name]: removeItem(currentCheckedListArray, value).join("|"),
      };

      if (changedMultipleCheckedList[name] === "") {
        delete multipleCheckedList[name];
      }
    }
    setMultipleCheckedList(() => changedMultipleCheckedList);

    const newValues = {
      ...values,
      ...changedMultipleCheckedList,
    };

    setValues(() => newValues);
  };

  const [yearMonth, setYearMonth] = React.useState({});
  const handleYearMonth = (name, value) => {
    const changedYearMonth = {
      ...yearMonth,
      [name]: value,
    };

    setYearMonth(() => changedYearMonth);

    setValues({
      ...values,
      ...changedYearMonth,
    });
  };

  if (formConfig === undefined) return <LoadingPage />;

  return [
    formConfig.map((input, index) => {
      switch (input.type.toUpperCase()) {
        case "EMAIL":
          return <EmailForm key={index} input={input} handler={handleChanged} />;
        case "PHONE":
          return <PhoneForm key={index} input={input} handler={handleChanged} />;
        case "PASSWORD":
          return <PasswordForm key={index} input={input} handler={handleChanged} />;
        case "SHORT":
          return <ShortTextForm key={index} input={input} handler={handleChanged} />;
        case "LONG":
          return <LongTextForm key={index} input={input} handler={handleChanged} />;
        case "SELECT":
          return <SelectForm key={index} input={input} handler={handleChanged} inputs={inputs} />;
        case "CHECK":
          return <CheckForm key={index} input={input} handler={handleChecked} checkedList={checkedList} />;
        case "RADIO":
          return <RadioForm key={index} input={input} handler={handleChanged} />;
        case "MCHECK":
          return <MultiCheckForm key={index} input={input} handler={handleMultipleChecked} checkedList={multipleCheckedList} />;
        case "YEARMONTH":
          return <YearMonthForm key={index} input={input} handler={handleYearMonth} />;
        default:
          return null;
      }
    }),
    values,
  ];
}

const MAX_LENGTH = 25;

function overCut(txt){
  if (txt.length > MAX_LENGTH){
    txt = txt.substr(0, MAX_LENGTH) + "...";
  }

  return txt
}

function overCutDummy(txt){
  if (txt.length > MAX_LENGTH){
    return txt;
  }else{
    return ""
  }

}

function SelectForm({ input, handler, inputs }) {
  return (
    <StyledFormElement input={input}>
      <FormControl fullWidth>
        {/* <InputLabel id={input.field}>{input.label}</InputLabel> */}
        <Select
          sx={{ ...FORMSTYLE.formInputRadius }}
          // labelId={input.field}
          id={input.fields}
          name={input.field}
          value={inputs[input.field] || ""}
          // label={`${input.label} ${input.required ? "(필수항목)" : ""}`}
          onChange={handler}
          displayEmpty
        >
          <MenuItem disabled value="">
            <span className="form-placeholder">{overCut(input.desc)}</span>
          </MenuItem>
          {input.options
            .split("|")
            .map((value) => value.trim())
            .map((option, idx) => (
              <MenuItem key={idx} value={option}>
                {option}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <div className="form-desc" style={{marginTop:"0.2rem"}}>{overCutDummy(input.desc)}</div>
    </StyledFormElement>
  );
}

function YearMonthForm({ input, handler }) {
  const occu_month = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const [year, setYear] = React.useState(0);
  const [month, setMonth] = React.useState(0);

  const handleYear = (e) => {
    const max = 50;
    const min = 0;

    if (e.target.value) {
      let value = parseInt(e.target.value, 10);

      if (value > max) value = max;
      if (value < min) value = min;

      setYear(() => value);
    } else {
      setYear(() => "");
    }

    handler(input.field, parseInt(e.target.value, 10) * 12 + parseInt(month, 10));
  };

  const handleMonth = (e) => {
    setMonth(() => e.target.value);
    handler(input.field, parseInt(year, 10) * 12 + parseInt(e.target.value, 10));
  };

  return (
    <>
      <StyledFormElement input={input}>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <Box sx={{ flexGrow: 1, mr: 3 }}>
            <FormControl fullWidth>
              <TextField
                id="year"
                name="year"
                label="년"
                value={year}
                dispaly="inline"
                onChange={handleYear}
                InputProps={{
                  inputProps: {
                    max: 50,
                    min: 0,
                    width: "100ch",
                  },
                  style: {
                    ...FORMSTYLE.formInputRadius,
                  },
                }}
                type="number"
              />
            </FormControl>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="month">개월</InputLabel>
              <Select sx={{ ...FORMSTYLE.formInputRadius }} labelId="month" name="month" value={month} label="개월" onChange={handleMonth}>
                {occu_month.map((option, idx) => (
                  <MenuItem key={idx} value={option} selected={idx === 1}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </StyledFormElement>
    </>
  );
}

function ShortTextForm({ input, handler }) {
  return (
    <StyledFormElement input={input}>
      <FormControl fullWidth>
        <TextField sx={{ ...FORMSTYLE.formInputRadius }} id={input.field} name={input.field} value={input.value} onChange={handler} placeholder={overCut(input.desc)}>
          {input.label}
        </TextField>
      </FormControl>
      <div className="form-desc" style={{marginTop:"0.2rem"}}>{overCutDummy(input.desc)}</div>
    </StyledFormElement>
  );
}

function LongTextForm({ input, handler }) {
  return (
    <StyledFormElement input={input}>
      <FormControl fullWidth>
        <TextField multiline rows={6} id={input.field} name={input.field} value={input.value} onChange={handler} placeholder={input.desc}>
          {input.label}
        </TextField>
      </FormControl>
    </StyledFormElement>
  );
}

function EmailForm({ input, handler }) {
  return (
    <StyledFormElement input={input}>
      <FormControl fullWidth>
        <TextField
          sx={{ ...FORMSTYLE.formInputRadius }}
          type="email"
          placeholder={input.placeholder}
          id={input.field}
          name={input.field}
          value={input.value}
          onChange={handler}
          autoComplete="on"
        >
          {input.placeholder}
        </TextField>
      </FormControl>
    </StyledFormElement>
  );
}

function PasswordForm({ input, handler }) {
  return (
    <StyledFormElement input={input}>
      <FormControl fullWidth>
        <TextField
          sx={{ ...FORMSTYLE.formInputRadius }}
          type="password"
          placeholder={overCut(input.desc)}
          id={input.field}
          name={input.field}
          value={input.value}
          onChange={handler}
          autoComplete="off"
        >
          {input.placeholder}
        </TextField>
      </FormControl>
      <div className="form-desc" style={{marginTop:"0.2rem"}}>{overCutDummy(input.desc)}</div>
    </StyledFormElement>
  );
}

function PhoneForm({ input, handler }) {
  return (
    <StyledFormElement input={input}>
      <FormControl fullWidth>
        <TextField
          sx={{ ...FORMSTYLE.formInputRadius }}
          placeholder={input.placeholder}
          id={input.field}
          name={input.field}
          value={input.value}
          onChange={handler}
          autoComplete="on"
        >
          {input.placeholder}
        </TextField>
      </FormControl>
    </StyledFormElement>
  );
}

function RadioForm({ input, handler }) {
  return (
    <StyledFormElement input={input}>
      <FormControl fullWidth component="fieldset">
        {!input.desc && (
          <FormLabel component="legend" sx={{ mt: 7 }}>
            {input.label}
          </FormLabel>
        )}
        <RadioGroup row id={input.field} name={input.field} onChange={handler}>
          {input.options
            .split("|")
            .map((value) => value.trim())
            .map((option, idx) => (
              <FormControlLabel key={idx} value={option} control={<Radio />} label={option} />
            ))}
        </RadioGroup>
      </FormControl>
    </StyledFormElement>
  );
}

function CheckForm({ input, handler, checkedList }) {
  return (
    <StyledFormElement input={input}>
      <FormControl fullWidth component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id={input.field}
                name={input.field}
                checked={checkedList[input.field] !== undefined ? checkedList[input.field] : input.default !== null ? true : false}
                onChange={handler}
              />
            }
            label={input.label}
          />
        </FormGroup>
      </FormControl>
    </StyledFormElement>
  );
}

function MultiCheckForm({ input, handler }) {
  return (
    <>
      <StyledFormElement input={input}>
        <FormControl fullWidth component="fieldset" variant="standard">
          {!input.desc && (
            <FormLabel component="legend" sx={{ mt: 1 }}>
              {input.label}
            </FormLabel>
          )}
          <FormGroup row>
            {input.options
              .split("|")
              .map((value) => value.trim())
              .map((option, idx) => (
                <FormControlLabel key={idx} value={option} control={<Checkbox onClick={handler} name={input.field} value={option} />} label={option} />
              ))}
          </FormGroup>
        </FormControl>
      </StyledFormElement>
    </>
  );
}

function StyledFormElement({ children, input }) {
  return (
    <div className="form-wrapper">
      {input.type !== "CHECK" ? (
        <div className="form-label-wrapper">
          <CircleIcon sx={{ fontSize: "1.0rem" }} />
          <span className="form-label">
            {input.label}
            <span className="form-required">{`${input.required ? "(필수항목)" : ""}`}</span>
          </span>
        </div>
      ) : null}
      <div className="form-component-wrapper">{children}</div>
      {(input.type === "CHECK" || input.type === "RADIO" || input.type === "MCHECK") && (
        <div className="form-desc-wrapper">
          <span className="form-desc">
            {input.desc}
            {input.type === "CHECK" ? <span className="form-required">{`${input.required ? "(필수항목)" : ""}`}</span> : null}
          </span>
        </div>
      )}
    </div>
  );
}
