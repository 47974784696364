import * as React from "react";
import { useNavigate } from "react-router-dom";

import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";

import { useScrollTop } from "commons/hooks/general";
import Copyright from "commons/components/Copyright";
import Footer from "tools/layouts/Footer";

import { DEBUG } from "commons/preferences/config";
import { TOOL_BASE_PATH, TOOL_PAGE_TITLE } from "tools/preferences/config";
import { useUniqueKeyInputBox } from "tools/hooks/useUniqueKeyInputBox";

export default function RRIPage() {
  const navigator = useNavigate();

  useScrollTop();

  const [preRriPrmUniqueKeyInputBox, preRriPrmUniqueKeyInputBoxOpen] = useUniqueKeyInputBox("Pre RRI Premium", TOOL_BASE_PATH.PRE_RRI_PRM);
  const [pstRriPrmUniqueKeyInputBox, pstRriPrmUniqueKeyInputBoxOpen] = useUniqueKeyInputBox("Post RRI Premium", TOOL_BASE_PATH.PST_RRI_PRM);

  return (
    <>
      <Header>{`${TOOL_PAGE_TITLE["RRI"]}`}</Header>
      <Content>
        <div className="page_container div-center">
          <div className="page_headline page_center">퇴직 준비 되셨어요?</div>

          <div className="tool-page-tool-wrapper alone">
            <div>&nbsp;</div>
            <div className="tool-page-tool-abbr">Re:PLAT - RRI</div>
            <div className="tool-page-tool-name">퇴직 준비도 진단</div>
            <div className="tool-page-tool-en">Retirement Readiness Index<br /><br /></div>

            <div className="tool-page-tool-desc lineheight_wide">
              퇴직준비도 진단 솔루션은
              <br />
              <span class="accent_text">40대 이상 중장년 세대</span>에게 최적화된<br /><span class="accent_text">퇴직준비 및 실행 솔루션</span>으로서,
              <br />
              <span class="accent_text">현직자와 퇴직자</span> 모두 진단 가능합니다.
            </div>
          </div>
          <div>&nbsp;</div>

          <div className="tool-page-tool-abbr"><span class="page_title accent_text">재직 여부를 말씀해 주세요.</span></div>
          <div className="tool-page-button-wrapper">
            <button
              className="tool-page-button-tool alone-button"
              style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
              onClick={() => navigator(`${TOOL_BASE_PATH.PRE_RRI_LTE}`)}
            >
            현직에 있습니다
            </button>
          </div>
          <div className="tool-page-button-wrapper">
            <button
              className="tool-page-button-tool alone-button"
              style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
              onClick={() => navigator(`${TOOL_BASE_PATH.PST_RRI_LTE}`)}
            >
              퇴직했습니다
            </button>
          </div>
          <div></div>
          <div className="page_section_comment page_center">최근 퇴직이 결정된 분들은 <span class="accent_text">'퇴직했습니다'</span>를 선택하세요.</div>
          {DEBUG && (
              <div className="tool-page-tool-wrapper premium-wrapper">
                <div className="tool-page-tool-name">현직자용 RRI Premium</div>
                <div className="tool-page-tool-phrase">퇴직준비 되셨어요?</div>
                <div className="tool-page-tool-desc">
                  테스트용
                </div>
                <div className="tool-page-button-wrapper">
                  <button
                    className="tool-page-button-tool "
                    style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
                    onClick={() => preRriPrmUniqueKeyInputBoxOpen()}
                  >
                    현직자용 RRI Premium
                  </button>
                </div>
              </div>
            )}
            {DEBUG && (
              <div className="tool-page-tool-wrapper premium-wrapper">

                <div className="tool-page-tool-name">퇴직자용 RRI Premium</div>
                <div className="tool-page-tool-phrase">퇴직 적응 되셨어요?</div>
                <div className="tool-page-tool-desc">
                  테스트용
                </div>
                <div className="tool-page-button-wrapper">
                  <button
                    className="tool-page-button-tool"
                    style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
                    onClick={() => pstRriPrmUniqueKeyInputBoxOpen()}
                  >
                    퇴직자용 RRI Premium
                  </button>
                </div>
              </div>
            )}
        </div>
      </Content>
      <Footer>
        <Copyright />
      </Footer>
      <div>
        {preRriPrmUniqueKeyInputBox}
        {pstRriPrmUniqueKeyInputBox}
      </div>
    </>
  );
}
