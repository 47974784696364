import * as React from "react";
import { useNavigate } from "react-router-dom";
import { INTRO_PAGE_NEXT_BUTTON_PATH } from "tools/preferences/config";
import useUniqueKey from "tools/hooks/useUniqueKey";

export default function IntroPageButton({ toolCode }) {
  const navigator = useNavigate();

  const uniqueKey = useUniqueKey(toolCode);
  const uniqueKeyQuery = uniqueKey ? `/?uniqueKey=${uniqueKey}` : "";

  return (
    <>
      <div className="div-center">
        <button className={"next-button"} onClick={() => navigator(`${INTRO_PAGE_NEXT_BUTTON_PATH[toolCode]}${uniqueKeyQuery}`)}>
          시작하기
        </button>
      </div>
    </>
  );
}
