const ANSWERS_LIST_TOTAL = {
  answered_at_formatted: "응답일시",
  answerer: "응답자",
  tool_name: "Tool",
  "raw_answers.profiles.agree": "개인정보 사용",
  "raw_answers.profiles.newsletter": "뉴스레터 구독",
  durations: "소요시간",
  hidden: [3, 4, 5],
};

const ANSWERS_LIST_RRI_LTE = {
  answered_at_formatted: "응답일시",
  answerer: "응답자",
  tool_name: "Tool",
  "raw_answers.additionals.changing_jobs": "이직 횟수",
  "raw_answers.additionals.retirement_position": "퇴직 당시 직급",
  "raw_answers.additionals.retirement_elapsed": "퇴직 후 경과 기간",
  "raw_answers.additionals.total_occupation": "총 경력기간(월)",
  "raw_answers.additionals.valuable_goal": "목표가치",
  "raw_answers.retired": "퇴직 여부",
  "raw_answers.profiles.agree": "개인정보 사용",
  "raw_answers.profiles.newsletter": "뉴스레터 구독",
  durations: "소요시간",
  hidden: [3, 4, 5, 6, 7, 8, 9, 10, 11],
};

const ANSWERS_LIST_NPT_LTE = {
  answered_at_formatted: "응답일시",
  answerer: "응답자",
  tool_name: "Tool",
  "raw_answers.additionals.changing_jobs": "이직 횟수",
  "raw_answers.additionals.now_position": "현재 직급",
  "raw_answers.additionals.total_occupation": "총 경력기간(월)",
  "raw_answers.additionals.valuable_goal": "목표가치",
  "raw_answers.retired": "퇴직 여부",
  "raw_answers.profiles.agree": "개인정보 사용",
  "raw_answers.profiles.newsletter": "뉴스레터 구독",
  durations: "소요시간",
  hidden: [3, 4, 5, 6, 7, 8, 9, 10],
};

const ANSWERS_LIST_CRC_STD = {
  answered_at_formatted: "응답일시",
  answerer: "응답자",
  tool_name: "Tool",
  "raw_answers.additionals.company_type": "기업 분류",
  "raw_answers.additionals.company_size": "기업 규모(종업원 수)",
  "raw_answers.additionals.company_category": "산업 분류",
  "raw_answers.additionals.owner_team": "부서",
  "raw_answers.additionals.owner_level": "직급",
  "raw_answers.profiles.company_name": "소속사",
  "raw_answers.profiles.owner_name": "이름",
  "raw_answers.profiles.owner_phone": "연락처",
  "raw_answers.profiles.retirement_policy": "퇴직 정책 관련 관심 주제",
  // "raw_answers.profiles.comment": "퇴직 정책 관련 현황",
  "raw_answers.profiles.agree": "개인정보 사용",
  "raw_answers.profiles.newsletter": "뉴스레터 구독",
  durations: "소요시간",
  hidden: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
};

const ANSWERS_LIST_CRC_EXT = {
  answered_at_formatted: "응답일시",
  answerer: "응답자",
  tool_name: "Tool",
  "raw_answers.additionals.company_type": "기업 분류",
  "raw_answers.additionals.company_size": "기업 규모(종업원 수)",
  "raw_answers.additionals.company_category": "산업 분류",
  "raw_answers.additionals.owner_team": "부서",
  "raw_answers.additionals.owner_level": "직급",
  "raw_answers.profiles.company_name": "소속사",
  "raw_answers.profiles.owner_name": "이름",
  "raw_answers.profiles.owner_phone": "연락처",
  "raw_answers.profiles.retirement_policy": "퇴직 정책 관련 관심 주제",
  // "raw_answers.profiles.comment": "퇴직 정책 관련 현황",
  "raw_answers.profiles.agree": "개인정보 사용",
  "raw_answers.profiles.newsletter": "뉴스레터 구독",
  durations: "소요시간",
  hidden: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
};

const ANSWERS_LIST_PMA_STD = {
  answered_at_formatted: "응답일시",
  answerer: "응답자",
  tool_name: "Tool",
  "raw_answers.additionals.company_type": "기업 분류",
  "raw_answers.additionals.company_size": "기업 규모(종업원 수)",
  "raw_answers.additionals.company_category": "산업 분류",
  "raw_answers.additionals.owner_team": "부서",
  "raw_answers.additionals.owner_level": "직급",
  "raw_answers.profiles.company_name": "소속사",
  "raw_answers.profiles.owner_name": "이름",
  "raw_answers.profiles.owner_phone": "연락처",
  "raw_answers.profiles.early_strategy": "혁신 제도",
  // "raw_answers.profiles.comment": "OKR 추진 현황",
  "raw_answers.profiles.agree": "개인정보 사용",
  "raw_answers.profiles.newsletter": "뉴스레터 구독",
  durations: "소요시간",
  hidden: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
};

const ANSWERS_LIST_PMA_EXT = {
  answered_at_formatted: "응답일시",
  answerer: "응답자",
  tool_name: "Tool",
  "raw_answers.additionals.company_type": "기업 분류",
  "raw_answers.additionals.company_size": "기업 규모(종업원 수)",
  "raw_answers.additionals.company_category": "산업 분류",
  "raw_answers.additionals.owner_team": "부서",
  "raw_answers.additionals.owner_level": "직급",
  "raw_answers.profiles.company_name": "소속사",
  "raw_answers.profiles.owner_name": "이름",
  "raw_answers.profiles.owner_phone": "연락처",
  "raw_answers.profiles.early_strategy": "혁신 제도",
  // "raw_answers.profiles.comment": "OKR 추진 현황",
  "raw_answers.profiles.agree": "개인정보 사용",
  "raw_answers.profiles.newsletter": "뉴스레터 구독",
  durations: "소요시간",
  hidden: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
};

const ANSWERS_LIST_PRE_RRI = {
  answered_at_formatted: "응답일시",
  answerer: "응답자",
  tool_name: "Tool",
  "raw_answers.additionals.company_name": "재직 회사",
  "raw_answers.additionals.changing_jobs": "이직 횟수",
  "raw_answers.additionals.total_occupation": "총 경력기간(월)",
  "raw_answers.additionals.valuable_goal": "목표가치",
  "raw_answers.profiles.agree": "개인정보 사용",
  "raw_answers.profiles.newsletter": "뉴스레터 구독",
  durations: "소요시간",
  hidden: [3, 4, 5, 6, 7, 8],
};

const ANSWERS_LIST_PST_RRI = {
  answered_at_formatted: "응답일시",
  answerer: "응답자",
  tool_name: "Tool",
  "raw_answers.additionals.company_name": "최종 회사",
  "raw_answers.additionals.changing_jobs": "이직 횟수",
  "raw_answers.additionals.retirement_position": "퇴직 당시 직급",
  "raw_answers.additionals.retirement_elapsed": "퇴직 후 경과 기간",
  "raw_answers.additionals.total_occupation": "총 경력기간(월)",
  "raw_answers.additionals.valuable_goal": "목표가치",
  "raw_answers.profiles.agree": "개인정보 사용",
  "raw_answers.profiles.newsletter": "뉴스레터 구독",
  durations: "소요시간",
  hidden: [3, 4, 5, 6, 7, 8, 9, 10],
};

export const ANSWERS_LIST = {
  "TOTAL": ANSWERS_LIST_TOTAL,
  "RRI_LTE": ANSWERS_LIST_RRI_LTE,
  "NPT_LTE": ANSWERS_LIST_NPT_LTE,
  "CRC_STD": ANSWERS_LIST_CRC_STD,
  "CRC_EXT": ANSWERS_LIST_CRC_EXT,
  "PMA_STD": ANSWERS_LIST_PMA_STD,
  "PMA_EXT": ANSWERS_LIST_PMA_EXT,
  //////////////////////
  "RRI_LTE_NEW": ANSWERS_LIST_RRI_LTE,

  "PRE_RRI_LTE": ANSWERS_LIST_PRE_RRI,
  "PRE_RRI_PRM": ANSWERS_LIST_PRE_RRI,

  "PST_RRI_LTE": ANSWERS_LIST_PST_RRI,
  "PST_RRI_PRM": ANSWERS_LIST_PST_RRI  
}

export const RESULTS_LIST = {
  answered_at_formatted: "응답일시",
  answerer: "응답자",
  tool_name: "Tool",
  phase_name: "분석 결과",
  hidden: [],
};