import * as React from "react";

import { useRecoilState } from "recoil";
import { answersState } from "tools/atoms/atoms";
import { Forms } from "tools/components/Forms";
import { useAlertDialog } from "commons/hooks/general";
import { checkRequiredField, validateValue } from "commons/utils/helpers";
import { DEBUG } from "commons/preferences/config";

export function useFormPage(segment, formConfig) {
  const [answers, setAnswers] = useRecoilState(answersState);

  const [forms, formValues] = Forms(formConfig);

  const [alertDialog, openAlertDialog, setAlertDialogContents] = useAlertDialog();

  const formValidate = () => {
    const [requiredCheckResult, checkFields] = checkRequiredField(formConfig, formValues);
    const [validateResult, invalidFileds] = validateValue(formConfig, formValues);

    if (DEBUG) console.log("requiredCheckResult: " + requiredCheckResult);
    if (DEBUG) console.log("validateResult: " + validateResult);

    if (!requiredCheckResult) {
      setAlertDialogContents({
        title: "필수 입력 항목 확인",
        alertList: checkFields,
        message: " 은/는 필수 입력 항목입니다.",
      });
      openAlertDialog();
      return false;
    } else if (!validateResult) {
      setAlertDialogContents({
        title: "입력 항목 유효성 확인",
        alertList: invalidFileds,
        message: " 항목의 입력 형식을 확인해 주세요.",
      });
      openAlertDialog();
      return false;
    } else {
      return true;
    }
  };
  React.useEffect(() => {
    setAnswers({ ...answers, [segment]: formValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  React.useEffect(() => {
    if (DEBUG) console.log("Global Answers:" + JSON.stringify(answers));
  }, [answers]);

  return [forms, alertDialog, formValidate];
}
