import CryptoJS from "crypto-js";
export function profileEncrypt(email, password) {
    function genToken(length) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
  
      return result;
    }
  
    const _deli = genToken(8);
  
    const plaintext = `${email}${_deli}${password}`;
  
    const _key = genToken(16);
    const _iv = _key;
  
    const key = CryptoJS.enc.Utf8.parse(_key);
    const iv = CryptoJS.enc.Utf8.parse(_iv);
    const srcs = CryptoJS.enc.Utf8.parse(plaintext);
  
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  
    return `${encrypted.ciphertext.toString()}${_key}${_deli}`;
  }