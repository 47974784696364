import TopScroll from "commons/components/TopScroll";
import * as React from "react";
export default function Content({ children }) {
  return (
    <>
      <div className="layout-content-wrapper">
        <div className="layout-content">{children}</div>
        <TopScroll />
      </div>
    </>
  );
}
