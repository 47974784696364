import * as React from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { premiumUniqueKeyState, toolState } from "tools/atoms/atoms";
import { Box } from "@mui/material";

import { useSetExtraValue, useRequestTemplate } from "tools/hooks/general";
import { useScrollTop, useQueryParams } from "commons/hooks/general";
import { requestPremiumPermisson } from "tools/api/api";
import { TOOL_TITLE, PREMIUM_PERMISSION_TOKEN, TOOL_PAGE_TITLE } from "tools/preferences/config";

import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";
import LoadingPage from "commons/components/LoadingPage";
import Copyright from "commons/components/Copyright";
import IntroPageButton from "tools/components/IntroPageButton";
import RedirectBox from "commons/components/RedirectBox";
import Footer from "tools/layouts/Footer";

import { localStorageSetItemWithExpiredTime, localStorageRemoveItem } from "commons/utils/localStorage";
import { DEBUG } from "commons/preferences/config";

export default function PremiumIntroPage({ toolCode, nextPage }) {
  const uniqueKey = useQueryParams().uniqueKey;
  const setPremiumUniqueKey = useSetRecoilState(premiumUniqueKeyState);

  // usePreventRefresh();
  // usePreventBack();
  useScrollTop();
  useRecoilState(toolState(toolCode));
  const [loading, template] = useRequestTemplate(`${toolCode}_INTRO`);

  const [premiumUserInfo, setPremiumUserInfo] = React.useState(null);

  const [errorInfo, setErrorInfo] = React.useState({});
  useSetExtraValue("timestamp_page", "intro_page");

  React.useEffect(() => {
    async function fetchPremiumPermissonInfo() {
      if (uniqueKey) {
        const permissionCheckResult = await requestPremiumPermisson(uniqueKey);

        if (DEBUG) console.log(permissionCheckResult);

        if (!permissionCheckResult.error) {
          localStorageRemoveItem(PREMIUM_PERMISSION_TOKEN);
          setPremiumUniqueKey(uniqueKey);

          const premiumUserCompany = {
            uniqueKey: uniqueKey,
            name: permissionCheckResult.owner.company.name,
            type: permissionCheckResult.owner.company.type,
            size: permissionCheckResult.owner.company.size,
            category: permissionCheckResult.owner.company.category,
          };

          setPremiumUserInfo(premiumUserCompany);
          localStorageSetItemWithExpiredTime(PREMIUM_PERMISSION_TOKEN, premiumUserCompany);
        } else {
          setErrorInfo(permissionCheckResult);
        }
      } else {
        setErrorInfo({
          error: "MissingUniqueKey",
          message: "프리미엄 도구를 사용하기 위해서는 정확한 고유키가 필요합니다.<br>고유키가 누락된 링크입니다.<br>담당자에게 문의하세요",
        });
      }
    }

    fetchPremiumPermissonInfo();
  }, [uniqueKey, setPremiumUniqueKey]);

  if (loading) return <LoadingPage />;

  return (
    <>
      <Header>{`${TOOL_TITLE[toolCode]} ${TOOL_PAGE_TITLE["INTRO"]}`}</Header>
      <Content>
        {premiumUserInfo ? (
          <>
            <Box dangerouslySetInnerHTML={{ __html: template[0] }} />
            <IntroPageButton toolCode={toolCode} />
            {template[1] && <Box dangerouslySetInnerHTML={{ __html: template[1] }} />}
          </>
        ) : (
          <RedirectBox title={errorInfo.error} message={errorInfo.message} copyright={false} redirect_to="툴 메인페이지로 가기" />
        )}
      </Content>
      <Footer>
        <Copyright />
      </Footer>
    </>
  );
}
