import * as React from "react";
import { useNavigate } from "react-router-dom";

import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";

import { useScrollTop } from "commons/hooks/general";
import Copyright from "commons/components/Copyright";

import { DEBUG } from "commons/preferences/config";
import { TOOL_BASE_PATH, TOOL_PAGE_TITLE } from "tools/preferences/config";
import Footer from "tools/layouts/Footer";

import { useUniqueKeyInputBox } from "tools/hooks/useUniqueKeyInputBox";

export default function SCockpitPage() {
  const navigator = useNavigate();

  useScrollTop();

  const [pmaPrmUniqueKeyInputBox, pmaPrmUniqueKeyInputBoxOpen] = useUniqueKeyInputBox("PMA Premium", TOOL_BASE_PATH.PMA_EXT);
  const [crcPrmUniqueKeyInputBox, crcPrmUniqueKeyInputBoxOpen] = useUniqueKeyInputBox("CRC Premium", TOOL_BASE_PATH.CRC_EXT);

  return (
    <>
      <Header>{`${TOOL_PAGE_TITLE["SCOCKPIT"]}`}</Header>
      <Content>
        <div className="page_container div-center">
          <div className="page_headline page_center">S - Cockpit 전략 솔루션</div>
          <div className="tool-page-wrapper">
            <div className="tool-page-tool-wrapper">
              <div className="tool-page-tool-abbr">PMA</div>
              <div className="tool-page-tool-name">PMA 성과경영 성숙도 진단</div>
              <div className="tool-page-tool-en">Performance Management Maturity Assessment</div>
              <div className="tool-page-tool-phrase">
                우리 회사의 성과경영 성숙도는
                <br />
                어느 단계일까요?
              </div>
              <div className="tool-page-tool-desc">
                회사의 전략 실행력과 성과경영 성숙도를 진단하고
                <br />
                단계 별 실행 가이드를 제시합니다.
              </div>
              <div className="tool-page-button-wrapper">
                <button className="tool-page-button-tool" onClick={() => navigator(TOOL_BASE_PATH.PMA_STD)}>
                  진단하기
                </button>
                <button className="tool-page-button-explain" onClick={() => window.open("https://www.whadam.co/pma")}>
                  자세히 보기
                </button>
              </div>
            </div>
            {DEBUG && (
              <div className="tool-page-tool-wrapper premium-wrapper">
                <div className="tool-page-tool-abbr">PMA Premium</div>
                <div className="tool-page-tool-name">PMA 성과경영 성숙도 진단</div>
                <div className="tool-page-tool-en">Performance Management Maturity Assessment</div>
                <div className="tool-page-tool-phrase">
                  우리 회사의 성과경영 성숙도는
                  <br />
                  어느 단계일까요?
                </div>
                <div className="tool-page-tool-desc">
                  회사의 전략 실행력과 성과경영 성숙도를 진단하고
                  <br />
                  단계 별 실행 가이드를 제시합니다.
                </div>
                <div className="tool-page-button-wrapper">
                  <button className="tool-page-button-tool" onClick={() => pmaPrmUniqueKeyInputBoxOpen()}>
                    진단하기
                  </button>
                  <button className="tool-page-button-explain" onClick={() => window.open("https://www.whadam.co/pma")}>
                    자세히 보기
                  </button>
                </div>
              </div>
            )}
            <div className="tool-page-tool-wrapper">
              <div className="tool-page-tool-abbr">CRC</div>
              <div className="tool-page-tool-name">CRC 퇴직문화 수준 진단</div>
              <div className="tool-page-tool-en">Corporate Resignation & Retirement Culture</div>
              <div className="tool-page-tool-phrase">
                우리 회사의 퇴직문화 수준은
                <br />
                어느 단계일까요?
              </div>
              <div className="tool-page-tool-desc">
                회사의 퇴직 문화와 조직 역량을 진단하고
                <br />
                단계 별 실행 가이드를 제시합니다.
              </div>
              <div className="tool-page-button-wrapper">
                <button className="tool-page-button-tool" onClick={() => navigator(TOOL_BASE_PATH.CRC_STD)}>
                  진단하기
                </button>
                <button className="tool-page-button-explain" onClick={() => window.open("https://www.whadam.co/crc")}>
                  자세히 보기
                </button>
              </div>
            </div>
            {DEBUG && (
              <div className="tool-page-tool-wrapper premium-wrapper">
                <div className="tool-page-tool-abbr">CRC Premium</div>
                <div className="tool-page-tool-name">CRC 퇴직문화 수준 진단</div>
                <div className="tool-page-tool-en">Corporate Resignation & Retirement Culture</div>
                <div className="tool-page-tool-phrase">
                  우리 회사의 퇴직문화 수준은
                  <br />
                  어느 단계일까요?
                </div>
                <div className="tool-page-tool-desc">
                  회사의 퇴직 문화와 조직 역량을 진단하고
                  <br />
                  단계 별 실행 가이드를 제시합니다.
                </div>
                <div className="tool-page-button-wrapper">
                  <button className="tool-page-button-tool" onClick={() => crcPrmUniqueKeyInputBoxOpen()}>
                    진단하기
                  </button>
                  <button className="tool-page-button-explain" onClick={() => window.open("https://www.whadam.co/crc")}>
                    자세히 보기
                  </button>
                </div>
              </div>
            )}

            <div className="tool-page-tool-wrapper">
              <div className="tool-page-tool-abbr">RPS</div>
              <div className="tool-page-tool-name">RPS 퇴직 지원 프로그램 진단</div>
              <div className="tool-page-tool-en">Retirement Program Solutions</div>
              <div className="tool-page-tool-phrase">
                우리 회사에 어울리는
                <br />
                퇴직 지원 프로그램은?
              </div>
              <div className="tool-page-tool-desc">
                회사의 퇴직 문화와 역량을 고려하여
                <br />
                퇴직 대상자 라이프스타일에 맞는 유형을 진단합니다.
              </div>
              <div className="tool-page-button-wrapper">
                <button className="tool-page-button-tool" onClick={() => navigator(TOOL_BASE_PATH.RPS)}>
                  진단하기
                </button>
                <button className="tool-page-button-explain" onClick={() => window.open("https://www.whadam.co/biz")}>
                  자세히 보기
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <Footer>
        <Copyright />
      </Footer>
      {pmaPrmUniqueKeyInputBox}
      {crcPrmUniqueKeyInputBox}
    </>
  );
}
