/* eslint-disable no-eval */
export function columnFromRaw(lists, columns) {
  columns.pop();

  return lists.map((raw) => {
    const column_datas = columns.map((column) => {
      const raw_value = eval(`raw.${column}`);
      let value;

      if (raw_value === true || raw_value === "TRUE") {
        value = "YES";
      } else if (raw_value === false || raw_value === "FALSE") {
        value = "NO";
      } else if (raw_value === null || raw_value === undefined) {
        value = "-";
      } else {
        value = raw_value;
      }

      return value;
    });

    return { uid: raw.uid.replaceAll("-", ""), rows: column_datas };
  });
}
