import * as React from "react";
import { useNavigate } from "react-router-dom";

import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";

import { useScrollTop } from "commons/hooks/general";
import Copyright from "commons/components/Copyright";

import { TOOL_BASE_PATH, TOOL_PAGE_TITLE } from "tools/preferences/config";
import Footer from "tools/layouts/Footer";

export default function RPSPage() {

  const navigator = useNavigate();

  useScrollTop();

  return (
    <>
      <Header>{`${TOOL_PAGE_TITLE["RPS"]}`}</Header>
      <Content>
        <div className="page_container div-center">
          <div className="page_headline page_center">누구를 위한 퇴직 프로그램을 구상중이신가요?</div>
          <div className="tool-page-wrapper">
            <div className="tool-page-tool-wrapper">
              <div className="tool-page-tool-name page_center">사장/부사장급 최고 경영진</div>
              <div className="tool-page-tool-phrase">
                많은 구성원들의 롤모델인
                <br />
                <span className="accent_text">최고 경영진급</span> 퇴직 대상자의
                <br />
                라이프스타일을 고려한
                <br />
                최적의 퇴직 프로그램을 제안합니다.
              </div>
              <div className="tool-page-button-wrapper">
                <button
                  className="tool-page-button-tool"
                  style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
                  onClick={() => navigator(`${TOOL_BASE_PATH.RPS_CLV}`)}
                >
                  맞춤 프로그램 확인
                </button>
              </div>
            </div>
            <div className="tool-page-tool-wrapper">
              <div className="tool-page-tool-name page_center">상무/전무급 임원직</div>
              <div className="tool-page-tool-phrase">
                갑작스러운 퇴직을 맞이한
                <br />
                <span className="accent_text">임원직 퇴직 대상자</span>의<br />
                라이프스타일을 고려한
                <br />
                최적의 퇴직 프로그램을 제안합니다.
              </div>
              <div className="tool-page-button-wrapper">
                <button
                  className="tool-page-button-tool"
                  style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
                  onClick={() => navigator(`${TOOL_BASE_PATH.RPS_ELV}`)}
                >
                  맞춤 프로그램 확인
                </button>
              </div>
            </div>
            <div className="tool-page-tool-wrapper">
              <div className="tool-page-tool-name page_center">정년 퇴직 대상자</div>
              <div className="tool-page-tool-phrase">
                <span className="accent_text">정년 퇴직 대상자</span>의<br />
                라이프스타일을 고려한
                <br />
                최적의 퇴직 프로그램을 제안합니다.
              </div>
              <div className="tool-page-button-wrapper">
                <button
                  className="tool-page-button-tool"
                  style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
                  onClick={() => navigator(`${TOOL_BASE_PATH.RPS_SLV}`)}
                >
                  맞춤 프로그램 확인
                </button>
              </div>
            </div>
            <div className="tool-page-tool-wrapper">
              <div className="tool-page-tool-name page_center">장기 근속 구성원</div>
              <div className="tool-page-tool-phrase">
                퇴직 대상인 <span className="accent_text">장기 근속 구성원</span>의<br />
                라이프스타일을 고려한
                <br />
                최적의 퇴직 프로그램을 제안합니다.
              </div>
              <div className="tool-page-button-wrapper">
                <button
                  className="tool-page-button-tool"
                  style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
                  onClick={() => navigator(`${TOOL_BASE_PATH.RPS_SLV}`)}
                >
                  맞춤 프로그램 확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <Footer>
        <Copyright />
      </Footer>
    </>
  );
}
