import * as React from "react";

import { Box } from "@mui/material";
import { requestHtmlTemplate } from "tools/api/api";
import { DEBUG } from "commons/preferences/config";

export default function Closing({ toolCode }) {
  const [closingTemplate, setClosingTemplate] = React.useState(null);

  React.useEffect(() => {
    async function getClosingTemplate() {
      if (DEBUG) {
        console.log("getClosingTemplate: " + toolCode);
      }

      const templateCode = `${toolCode}_CLOSING`;

      const template = await requestHtmlTemplate(templateCode);

      if (DEBUG) {
        console.log("Template: " + template);
      }

      setClosingTemplate(template);
    }

    getClosingTemplate();
  }, [toolCode]);

  return <>{closingTemplate && <Box dangerouslySetInnerHTML={{ __html: closingTemplate.html }} />}</>;
}
