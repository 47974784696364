import * as React from "react";

import icon_yes from "assets/images/icon-yes.svg";
import icon_no from "assets/images/icon-no.svg";

export function Yes({ size }) {
  return <img src={icon_yes} alt="Yes" width={size} />;
}

export function No({ size }) {
  return <img src={icon_no} alt="No" width={size} />;
}

export function True({ size }) {
  return <img src={icon_yes} alt="Yes" width={size} />;
}

export function False({ size }) {
  return <img src={icon_no} alt="No" width={size} />;
}

export default function ConvertMarks({ value, size = 16 }) {
  switch (`${value}`.toUpperCase()) {
    case "YES":
      return <Yes size={size} />;
    case "TRUE":
      return <True size={size} />;
    case "NO":
      return <No size={size} />;
    case "FALSE":
      return <False size={size} />;

    default:
      return value;
  }
}
