import * as React from "react";

import { requestProgram } from "tools/api/api";
import LoadingPage from "commons/components/LoadingPage";
import { MORE_PROGRAMS_URL } from "tools/preferences/config";
import { DEBUG } from "commons/preferences/config";

export default function Programs({ tool, phase }) {
  const [programs, setPrograms] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchPrograms() {
      const _programs = await requestProgram(tool, phase);
      if (DEBUG ) console.log(_programs);
      setPrograms(_programs.results);
      setLoading(false);
    }
    fetchPrograms();
  }, [tool, phase]);

  if (loading) return <LoadingPage />;

  return (
    <>
      {programs && (
        <>
          <a href={MORE_PROGRAMS_URL} target="blank" style={{ textDecoration: "none" }}>
            <div className="program_container">
              {programs.map((program, index) => {
                return (
                  <div key={index} className="program_items">
                    <div className="program_categorys">
                      <div className="program_category">{program.category1.name}</div>
                      {program.category2 && (
                        <>
                          <div className="program_category divider">|</div>
                          <div className="program_category">{program.category2.name}</div>
                        </>
                      )}
                    </div>
                    <div className="program_cover">
                      <img className="program_cover_image" alt={program.cover} src={program.cover} />
                    </div>
                    <div className="program_name">{program.name}</div>
                    <div className="program_desc1">{program.desc1}</div>
                    <div className="program_desc2">{program.desc2}</div>
                  </div>
                );
              })}
            </div>
          </a>
        </>
      )}
    </>
  );
}
