import * as React from "react";

export default function Footer({ children }) {
  return (
    <>
      <div className="dl-footer">
        <div className="d-content align-center">{children}</div>
      </div>
    </>
  );
}
