const DECIMAL = 1;

const driverChartLabels = {
  CRC_STD: {
    SHORT: ["마스터플랜", "연계성", "효율성", "운영 역량", "공유 가치"],
    LONG: [
      ["퇴직전략 이해도 및 퇴직 마스터플랜"],
      ["퇴직전략의", "HR 정책 연계성"],
      ["퇴직 실행 체계 및", "운영 효율성"],
      ["퇴직 교육 프로그램 설계 및", "운영 역량"],
      ["퇴직에 대한 인식도 및", "공유 가치"],
    ],
  },
  PMA_STD: {
    SHORT: ["비전", "리더십", "성과관리", "KPI", "혁신"],
    LONG: [
      ["비전 및 전략 체계화 정도"],
      ["리더십 스타일 및", "의사결정 체계 적합성"],
      ["성과관리 체계", "운영 수준"],
      ["KPI 도출 및", "목표 관리 역량"],
      ["혁신 수용도 및", "조직 역량"],
    ],
  },
  PRE_RRI_LTE: {
    SHORT: ["심리", "태도", "가치", "경쟁력", "준비도"],
    LONG: [["심리와 정서"], ["관계와 태도"], ["목표 가치와", "라이프 스타일"], ["커리어 경쟁력"], ["뉴업 준비도"]],
  },
  PRE_RRI_PRM: {
    SHORT: ["심리", "태도", "가치", "경쟁력", "준비도"],
    LONG: [["심리와 정서"], ["관계와 태도"], ["목표 가치와", "라이프 스타일"], ["커리어 경쟁력"], ["뉴업 준비도"]],
  },
  PST_RRI_LTE: {
    SHORT: ["심리", "태도", "가치", "경쟁력", "준비도"],
    LONG: [["심리와 정서"], ["관계와 태도"], ["목표 가치와", "라이프 스타일"], ["커리어 경쟁력"], ["뉴업 준비도"]],
  },
  PST_RRI_PRM: {
    SHORT: ["심리", "태도", "가치", "경쟁력", "준비도"],
    LONG: [["심리와 정서"], ["관계와 태도"], ["목표 가치와", "라이프 스타일"], ["커리어 경쟁력"], ["뉴업 준비도"]],
  },
};

// series colors: 단계별 색상 I ~ IV
const colorType = ["#4c1d95", "#0f766e", "#b45309", "#3b82f6"];

const legend = {
  you: {
    text: "나",
    color: "#6d28d9",
  },
  average: {
    text: "전체평균",
    color: "#ef4444",
  },
  benchmark: {
    text: "벤치마킹",
    color: "#059669",
  },
};

const seriesColor = [legend.you.color, legend.average.color, legend.benchmark.color];

export function getStandardDistributionChartOptions() {
  return {
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    fill: {
      opacity: 1,
    },

    colors: colorType,
    legend: {
      position: "top",
      horizontalAlign: "left",
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Iropke Batang",
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
      markers: {
        fillColors: seriesColor,
        radius: 12,
      },
      labels: {
        useSeriesColors: false,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };
}

export function getStandardDistributionChartSeries(datas) {
  return datas.distribution.map((phase) => ({
    name: phase.name,
    data: [parseFloat(phase.percent)],
  }));
}

export function getStandardToolChartOptions() {
  return {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "90%",
      },
    },
    yaxis: {
      show: false,
    },

    grid: {
      show: true,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },

    dataLabels: {
      formatter: function (val, opts) {
        return val.toFixed(DECIMAL);
      },
    },

    colors: [legend.you.color],
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: [legend.you.text, legend.average.text, legend.benchmark.text],
      markers: {
        fillColors: seriesColor,
        radius: 12,
      },
      fontSize: "16px",
      fontWeight: "bold",
      fontFamily: "Iropke Batang",
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
      labels: {
        useSeriesColors: false,
      },
    },
    xaxis: {
      min: 0,
      max: 100,
    },
  };
}

export function getStandardToolChartSeries(datas) {
  const data = [
    {
      x: "tool",
      y: datas.value,
      goals: [
        {
          name: legend.average.text,
          value: datas.avgValue,
          strokeWidth: 10,
          strokeHeight: 0,
          strokeLineCap: "round",
          strokeColor: legend.average.color,
        },
        {
          name: legend.benchmark.text,
          value: datas.bmValue,
          strokeWidth: 5,
          strokeHeight: 7,
          strokeLineCap: "round",
          strokeColor: legend.benchmark.color,
        },
      ],
    },
  ];

  return [
    {
      name: legend.you.text,
      data: data,
    },
  ];
}

export function getStandardDriverChartOptions(toolCode) {
  const DRIVER_LABELS_SHORT = driverChartLabels[toolCode].SHORT;
  const DRIVER_LABELS_LONG = driverChartLabels[toolCode].LONG;

  const standardDriverChartOptions = {
    chart: {
      height: "auto",
      toolbar: {
        show: false,
      },
    },

    legend: {
      fontSize: "16px",
      fontWeight: "bold",
      fontFamily: "Iropke Batang",
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
      labels: {
        useSeriesColors: false,
      },
    },
    colors: seriesColor,
    stroke: {
      width: 2,
    },
    fill: {
      opacity: 0.1,
    },
    markers: {
      size: 0,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return val.toFixed(DECIMAL);
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    responsive: [
      {
        breakpoint: 400,
        options: {
          xaxis: {
            categories: DRIVER_LABELS_SHORT,
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
          legend: {
            fontSize: "12px",
            offsetY: 0,
          },
          plotOptions: {
            radar: {
              size: 70,
            },
          },
          dataLabels: {
            enabled: false,
          },
        },
      },
      {
        breakpoint: 500,
        options: {
          xaxis: {
            categories: DRIVER_LABELS_SHORT,
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
          legend: {
            fontSize: "12px",
            offsetY: 0,
          },
          plotOptions: {
            radar: {
              size: 90,
            },
          },
          dataLabels: {
            enabled: false,
          },
        },
      },
      {
        breakpoint: 600,
        options: {
          xaxis: {
            categories: DRIVER_LABELS_LONG,
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
          legend: {
            fontSize: "12px",
            offsetY: 0,
          },
          plotOptions: {
            radar: {
              size: 110,
            },
          },
          dataLabels: {
            enabled: false,
          },
        },
      },
      {
        breakpoint: 700,
        options: {
          xaxis: {
            categories: DRIVER_LABELS_LONG,
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
          legend: {
            fontSize: "12px",
            offsetY: 0,
          },
          plotOptions: {
            radar: {
              size: 150,
            },
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          xaxis: {
            categories: DRIVER_LABELS_LONG,
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
          legend: {
            fontSize: "12px",
            offsetY: 0,
          },
          plotOptions: {
            radar: {
              size: 150,
            },
          },
        },
      },
      {
        breakpoint: 900,
        options: {
          plotOptions: {
            radar: {
              size: 200,
            },
          },
        },
      },

      {
        breakpoint: 1200,
        options: {
          plotOptions: {
            radar: {
              size: 220,
            },
          },
        },
      },
    ],
    plotOptions: {
      radar: {
        size: 280,
        polygons: {
          strokeColor: "#e8e8e8",
          fill: {
            colors: ["#f8f8f8", "#fff"],
          },
        },
        offsetY: 30,
      },
    },
    xaxis: {
      categories: DRIVER_LABELS_LONG,
      labels: {
        show: true,
        style: {
          colors: ["#333333", "#333333", "#333333", "#333333", "#333333"],
          fontSize: "12px",
          fontWeight: "bold",
        },
        offsetY: -10,
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        formatter: function (val, i) {
          if (i % 2 === 0) {
            return val;
          } else {
            return "";
          }
        },
      },
    },
  };
  // standardDriverChartOptions.responsive[0].options.xaxis.categories = DRIVER_LABELS_SHORT;
  // standardDriverChartOptions.xaxis.categories = DRIVER_LABELS_LONG;

  return standardDriverChartOptions;
}

export function getStandardDriverChartSeries(datas) {
  const values = datas.map((driver) => driver.moderated_value);
  const bmValues = datas.map((driver) => driver.bmValue);
  const avgValues = datas.map((driver) => driver.avgValue);

  return [
    {
      name: legend.you.text,
      data: values,
    },
    {
      name: legend.average.text,
      data: avgValues,
    },
    {
      name: legend.benchmark.text,
      data: bmValues,
    },
  ];
}

export function getStandardDivisionChartOptions(driver, order) {
  return {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    title: {
      text: `${order}. ${driver.name} : ${parseFloat(driver.moderated_value).toFixed(DECIMAL)}`,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: "Iropke Batang",
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "90%",
      },
    },
    dataLabels: {
      formatter: function (val, opts) {
        return val.toFixed(DECIMAL);
      },
    },
    colors: seriesColor,
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: [legend.you.text, legend.average.text, legend.benchmark.text],
      markers: {
        fillColors: seriesColor,
        radius: 12,
      },
      fontSize: "16px",
      fontWeight: "bold",
      fontFamily: "Iropke Batang",
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
      labels: {
        useSeriesColors: false,
      },
    },

    xaxis: {
      min: 0,
      max: 100,
    },
    yaxis: {
      labels: {
        minWidth: 100,
        maxWidth: 130,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          fontFamily: "Iropke Batang",
        },
      },
    },
  };
}

export function getStandardDvisionChartSeries(driver) {
  function _divisionBenchmarkOptions(name, value, bmValue, avgValue) {
    return {
      x: name,
      y: value,
      goals: [
        {
          name: legend.average.text,
          value: avgValue,
          strokeWidth: 10,
          strokeHeight: 0,
          strokeLineCap: "round",
          strokeColor: legend.average.color,
        },
        {
          name: legend.benchmark.text,
          value: bmValue,
          strokeWidth: 5,
          strokeHeight: 7,
          strokeLineCap: "round",
          strokeColor: legend.benchmark.color,
        },
      ],
    };
  }

  const data = driver.divisions.map(({ name, moderated_value, bmValue, avgValue }) => _divisionBenchmarkOptions(name, moderated_value, bmValue, avgValue));

  return [
    {
      name: legend.you.text,
      data: data,
    },
  ];
}
