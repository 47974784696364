import * as React from "react";

import Routers from "tools/routers/Routers";
import LoadingPage from "commons/components/LoadingPage";

function Root() {
  return (
    <>
      <React.Suspense fallback={<LoadingPage />}>
        <div className="layout-container">
          <Routers />
        </div>
      </React.Suspense>
    </>
  );
}

export default Root;
