import * as React from "react";

import { requestToolData } from "dashboards/api/api";
import { formattedFormValue } from "dashboards/utils/helpers";
import { useNavigate } from "react-router-dom";

import { DASHBOARD_PATH } from "dashboards/preferences/config";
import { DEBUG } from "commons/preferences/config";

export default function DetailViewer({ detailData, mode }) {
  const [toolData, setToolData] = React.useState(null);
  React.useEffect(() => {
    async function fetchToolData() {
      let _toolData = await requestToolData(detailData.tool);

      console.log(_toolData);

      if (mode === "ANSWER") {
        setToolData(_toolData);
      } else if (mode === "RESULT") {
        _toolData["drivers"] = detailData.evaluated.drivers;
        setToolData(_toolData);
      }
    }
    fetchToolData();
  }, [detailData, mode]);

  return (
    <>
      {toolData && (
        <>
          <ToolDescPart toolData={toolData} detailData={detailData} mode={mode} />
          {mode === "RESULT" && (
            <ResultPart phase={detailData.phase_name} value={detailData.evaluated.value} moderator={toolData.moderator} toolType={toolData.evaluation_type} />
          )}
          <QuestionnairePart toolData={toolData} detailData={detailData} />
          <AdditionalFormPart toolData={toolData} detailData={detailData} />
          <ProfileFormPart toolData={toolData} detailData={detailData} />
        </>
      )}
    </>
  );
}

function ResultPart({ phase, value, moderator, toolType }) {
  return (
    <>
      <div className="tool-data-text-wrapper">
        <div className="tool-data-text text-font-accent borderless header-divisions">분석 결과</div>
        <div className="tool-data-text borderless" style={{ flexDirection: "row" }}>
          {phase} &nbsp;&nbsp;&nbsp; {toolValue(value, toolType, moderator)}
        </div>
      </div>
    </>
  );
}

function AdditionalFormPart({ toolData, detailData }) {
  const additionalFormData = toolData.additionals;
  const additionalDetailData = detailData.raw_answers ? detailData.raw_answers.additionals : detailData.raw_answer.additionals;

  return <>{additionalFormData && <DisplayFormData formData={additionalFormData} answers={additionalDetailData} />}</>;
}

function ProfileFormPart({ toolData, detailData }) {
  const profilesFormData = toolData.profiles;
  const profilesDetailData = detailData.raw_answers ? detailData.raw_answers.profiles : detailData.raw_answer.profiles;

  return <>{profilesFormData && <DisplayFormData formData={profilesFormData} answers={profilesDetailData} />}</>;
}

function DisplayFormData({ formData, answers }) {
  return (
    <>
      {formData &&
        formData.map(
          (field, index) =>
            field.field !== "email" &&
            field.field !== "pin" && (
              <div key={`${field.code}_wrapper`} className="tool-data-form-wrapper">
                <div className="tool-data-text text-font-accent borderless header-divisions" key={`${field.code}_label`}>
                  {field.label}
                </div>
                <div className="tool-data-text borderless" key={`${field.code}_field`}>
                  {formattedFormValue(field.field, answers[field.field])}
                </div>
              </div>
            ),
        )}
    </>
  );
}

function ToolDescPart({ toolData, detailData, mode }) {
  const navigator = useNavigate();
  return (
    <>
      <div className="tool-data-text-wrapper">
        <div className="tool-data-text text-font-accent borderless header-divisions">도구</div>
        <div className="tool-data-text borderless">{toolData.remark}</div>
        <div className="tool-data-text text-font-accent borderless header-divisions">응답자</div>
        <div className="tool-data-text borderless">{detailData.answerer}</div>
        <div className="tool-data-text text-font-accent borderless header-divisions">응답일시</div>
        <div className="tool-data-text borderless">{detailData.answered_at_formatted}</div>
        {mode === "ANSWER" && (
          <div className="tool-data-text grid-analysys-result borderless" style={{ textAlign: "right", cursor: "pointer" }}>
            <span onClick={() => navigator(`${DASHBOARD_PATH.BASE}${DASHBOARD_PATH.RESULT_DETAIL}/${detailData.uid.replaceAll("-", "")}`)}>분석결과 ▶</span>
            <br />
            <span onClick={() => window.open(`/${toolData.result_path}/result/${detailData.uid.replaceAll("-", "")}/`)}>진단결과 ▶</span>
          </div>
        )}
      </div>
    </>
  );
}

function QuestionnairePart({ toolData, detailData }) {
  return (
    <>
      {toolData && (
        <>
          <QuestionnaireHeaderPart />
          <DriversPart drivers={toolData.drivers} detailData={detailData} moderator={toolData.moderator} toolType={toolData.evaluation_type} />
        </>
      )}
    </>
  );
}

function QuestionnaireHeaderPart() {
  return (
    <>
      <div className="tool-data-drivers-wrapper  tool-data-mobile-hidden">
        <div className="tool-data-drivers">
          <div className="tool-data-header header-drivers tool-data-mobile-hidden">가중치</div>
          <div className="tool-data-header header-drivers tool-data-mobile-hidden">Driver</div>
          <div className="tool-data-divisions">
            <div className="tool-data-header header-divisions tool-data-mobile-hidden">가중치</div>
            <div className="tool-data-header header-divisions tool-data-mobile-hidden">Division</div>
            <div className="tool-data-factors">
              <div className="tool-data-header header-factors tool-data-mobile-hidden">가중치</div>
              <div className="tool-data-header header-factors tool-data-mobile-hidden">Factor</div>
              <div className="tool-data-questions">
                <div className="tool-data-header header-questions">Questions</div>
                <div className="tool-data-options">
                  <div className="tool-data-header header-options">Options</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function DriversPart({ drivers, detailData, moderator, toolType }) {
  return (
    <>
      {drivers && (
        <div className="tool-data-drivers-wrapper">
          {drivers.map((driver, index) => (
            <div className="tool-data-drivers" key={index}>
              <div className="tool-data-number tool-data-mobile-hidden">{driver.weight}</div>
              <div className="tool-data-text tool-data-mobile-hidden">
                {driver.name}
                {/* ({driver.code}) */}
                {driver.en_name}
                <br />
                <span className="text-size-sm text-font-accent">{driver.desc}</span>
                {evaluatedValue(driver.value, moderator, toolType)}
              </div>

              <DivisionsPart divisions={driver.divisions} detailData={detailData} moderator={moderator} toolType={toolType} />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function DivisionsPart({ divisions, detailData, moderator, toolType }) {
  return (
    <>
      {divisions && (
        <div className="tool-data-divisions-wrapper">
          {divisions.map((division, index) => (
            <div className="tool-data-divisions" key={index}>
              <div className="tool-data-number tool-data-mobile-hidden">{division.weight}</div>
              <div className="tool-data-text tool-data-mobile-hidden">
                {division.name}
                {/* ({division.code}) */}
                {evaluatedValue(division.value, moderator, toolType)}
              </div>

              <FactorsPart factors={division.factors} detailData={detailData} moderator={moderator} toolType={toolType} />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function FactorsPart({ factors, detailData, moderator, toolType }) {
  return (
    <>
      {factors && (
        <div className="tool-data-factors-wrapper">
          {factors.map((factor, index) => (
            <div className="tool-data-factors" key={index}>
              <div className="tool-data-number tool-data-mobile-hidden">{factor.weight}</div>
              <div className="tool-data-text tool-data-mobile-hidden">
                {factor.name}
                {/* ({factor.code}) */}
                {evaluatedValue(factor.value, moderator, toolType)}
              </div>

              <QuestionsPart questions={factor.questions} detailData={detailData} moderator={moderator} toolType={toolType} />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function QuestionsPart({ questions, detailData, moderator, toolType }) {
  const answers = detailData.raw_answers ? detailData.raw_answers.answers : detailData.raw_answer.answers;

  if (DEBUG) console.log(answers);
  return (
    <>
      {questions && (
        <div className="tool-data-questions-wrapper">
          {questions.map((question, index) => (
            <div className="tool-data-questions" key={index}>
              <div className="tool-data-text">
                <span className="text-font-accent">
                  {question.number}. {question.text}
                  <span className="tool-data-mobile-hidden">{/* ({question.code}) */}</span>
                </span>
                {evaluatedValue(question.value, moderator, toolType)}
              </div>
              <OptionsPart options={question.options} selected={answers[question.code]} toolType={toolType} />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function OptionsPart({ options, selected, toolType }) {
  return (
    <>
      {options && (
        <div className="tool-data-options-wrapper">
          {options.map((option, index) => (
            <div className="tool-data-options" key={index}>
              <div className={parseInt(selected) === parseInt(option.number) ? "tool-data-text selected-option" : "tool-data-text"}>
                <span>
                  {option.number}. <span dangerouslySetInnerHTML={{ __html: option.text }}></span>
                </span>
              </div>
              {/* <div className="tool-data-text">{option.value}</div>
              <div className="tool-data-text">{option.evaluate}</div> */}
              {/* <Box dangerouslySetInnerHTML={{ __html: template[0] }} /> */}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function evaluatedValue(value, moderator, toolType, hidden = true) {
  if (toolType !== "POINT") {
    return (
      <>
        {moderator && (
          <div className={hidden && "tool-data-mobile-hidden"} style={hidden ? { marginTop: "2rem" } : {}}>
            <span className="text-size-md text-font-accent text-size-lg text-color-red" style={{ marginRight: "0.5rem" }}>
              {value ? `${moderatedValue(value, moderator, toolType)}` : ""}
            </span>
            <span className="text-size-xs text-color-gray">{value ? `[${justifiedValue(value, toolType)}]` : ""}</span>
          </div>
        )}
      </>
    );
  } else {
    return (
      <div className={hidden && "tool-data-mobile-hidden"} style={hidden ? { marginTop: "2rem" } : {}}>
        <span className="text-size-md text-font-accent text-size-lg text-color-red" style={{ marginRight: "0.5rem" }}>
          {value ? `${justifiedValue(value, toolType)}` : ""}
        </span>
      </div>
    );
  }
}

function toolValue(value, toolType, moderator) {
  if (toolType === "POINT") {
    return justifiedValue(value, toolType);
  } else {
    return evaluatedValue(value, moderator, false);
  }
}

function justifiedValue(value, toolType) {
  if (toolType === "POINT") {
    return parseFloat(value).toFixed(2);
  } else {
    return value.split(",").map((value) => parseFloat(value.trim()).toFixed(1));
  }
}

function moderatedValue(value, moderator, toolType) {
  if (toolType === "POINT") {
    return value;
  } else {
    const value_array = value.split(",").map((v) => parseFloat(v.trim()));
    const moderator_array = moderator.split(",").map((v) => parseFloat(v.trim()));

    return dot(value_array, moderator_array).toFixed(2);
  }

  // let moderatedValue = 0.0;

  // for (let i = 0; i < value_array.length; i++) {
  //   moderatedValue += parseFloat(value_array[i]) * parseFloat(moderator_array[i]);
  // }
  // return moderatedValue;
}

function dot(arr1, arr2) {
  return arr1.map((x, i) => arr1[i] * arr2[i]).reduce((m, n) => m + n);
}
