import * as React from "react";

import Header from "dashboards/layouts/Header";
import Sidebar from "dashboards/layouts/MainSidebar";
import Main from "dashboards/layouts/MainMain";
import Footer from "dashboards/layouts/Footer";

export default function MainPage({ children }) {
  return (
    <>
      <div className="d-container">
        <Header />
        <Sidebar>sidebar</Sidebar>
        <Main>{children}</Main>
        <Footer>footer</Footer>
      </div>
    </>
  );
}
