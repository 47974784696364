import * as React from "react";

import { PREMIUM_PERMISSION_TOKEN, PREMIUM_TOOL_CODE } from "tools/preferences/config";
import { localStorageGetItemWithExpiredTime } from "commons/utils/localStorage";

export default function useUniqueKey(toolCode) {
  const [premiumUserInfo, setPremiumUserInfo] = React.useState(null);

  React.useEffect(() => {
    if (PREMIUM_TOOL_CODE.includes(toolCode)) {
      const _premiumUserInfo = localStorageGetItemWithExpiredTime(PREMIUM_PERMISSION_TOKEN);
      if (_premiumUserInfo) {
        setPremiumUserInfo(_premiumUserInfo);
      }
    }
  }, [toolCode]);
  if (premiumUserInfo) {
    return premiumUserInfo.uniqueKey;
  } else {
    return null;
  }
}
