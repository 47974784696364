/* eslint-disable react/jsx-no-target-blank */

import { HOME_URL } from "tools/preferences/config";

export default function Copyright() {
  return (
    <>
      <a target="_blank" href={HOME_URL} className="copyright-link">
        리더들의 퇴직 플래너 화담,하다
      </a>
      <span className="copyright-text">Copyright © ECOINTO All rights reserved.</span>
    </>
  );
}
