import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter as Router } from "react-router-dom";

import { RecoilRoot } from "recoil";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "commons/preferences/theme";

import Root from "Root";

import "assets/css/index.css";

const htmlContainer = document.getElementById("root");

const root = ReactDOM.createRoot(htmlContainer);
root.render(
  <>
    {/* <React.StrictMode> */}
      <CssBaseline />
      <ThemeProvider theme={mainTheme}>
        <RecoilRoot>
          <Router>
            <Root />
          </Router>
        </RecoilRoot>
      </ThemeProvider>
    {/* </React.StrictMode> */}
  </>,
);
