import * as React from "react";
import { useSetRecoilState } from "recoil";
import { answersState } from "tools/atoms/atoms";
import { Box } from "@mui/material";
import { shuffledArray } from "commons/utils/helpers";
import { useSetExtraValue } from "tools/hooks/general";

export default function Questions({ question, answer, setAnswer, scramble, question_adress, setRepaint }) {
  const setAnswerState = useSetRecoilState(answersState);

  const [shuffledOrder, setShuffledOrder] = React.useState([]);
  useSetExtraValue("timestamp_question", question.code);

  React.useEffect(() => {
    setShuffledOrder(shuffledArray(question.option_items.length, scramble));
  }, [question, scramble]);

  const handleOptionClick = (optionIndex) => {
    setRepaint(false);
    const selectedAnswer = { [question.code]: optionIndex + 1 };
    setAnswer({
      ...answer,
      ...selectedAnswer,
    });

    setAnswerState({
      answers: {
        ...answer,
        ...selectedAnswer,
      },
    });
  };

  return (
    <>
      <div className="question_wrapper">
        {question_adress && <Box className="question-address">{question.division_name}</Box>}
        <Box className="question">{question.question}</Box>

        <Box className="options">
          {question.option_items.map((ele, index) => (
            <Box
              key={index}
              sx={answer[question.code] === index + 1 ? { order: shuffledOrder[index] } : { order: shuffledOrder[index] }}
              className={answer[question.code] === index + 1 ? "option-selected" : "option"}
              onClick={() => handleOptionClick(index)}
            >
              <Box dangerouslySetInnerHTML={{ __html: ele }} />
            </Box>
          ))}
        </Box>
      </div>
    </>
  );
}
