import * as React from "react";

import { useFetchListData } from "dashboards/hooks/useFetchListData";
import { DASHBOARD_API_URL, DASHBOARD_PATH } from "dashboards/preferences/config";
import { ANSWERS_LIST } from "dashboards/preferences/tables";
import { makeQueryString } from "dashboards/utils/makeQuery";
import { settingHeaderAndRows } from "dashboards/utils/settingTable";
import { useQueryParams } from "commons/hooks/general";

import MakeTable from "dashboards/components/makeTable/MakeTable";
import MakePagination from "dashboards/components/makeTable/MakePagination";

import MakeNavigationSection from "dashboards/components/sections/MakeNavigationSection";
import MakeTitleSection from "dashboards/components/sections/MakeTitleSection";
import MakeSearchSection from "dashboards/components/sections/MakeSearchSection";

export default function AnswersListPage() {
  const query = useQueryParams();

  const queryString = makeQueryString(query);

  const datas = useFetchListData(DASHBOARD_API_URL.ANSWERS_LIST, queryString);

  const table_definition = query.tool ? ANSWERS_LIST[`${query.tool}`] : ANSWERS_LIST["TOTAL"];

  const [header, rows] = settingHeaderAndRows(datas, table_definition);

  return (
    <>
      {datas && (
        <>
          <MakeNavigationSection />
          <MakeTitleSection />
          <MakeSearchSection action="/status/answers" />
          <MakeTable header={header} rows={rows} detail={DASHBOARD_PATH.ANSWER_DETAIL} />
          <MakePagination datas={datas} />
        </>
      )}
    </>
  );
}
