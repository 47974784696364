import * as React from "react";
import { Box, LinearProgress } from "@mui/material";

export default function LoadingPage() {
  return (
    <Box sx={{ height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ height: "10px", justifyItems: "center" }}></Box>
      <LinearProgress />
    </Box>
  );
}
