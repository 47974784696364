import * as React from "react";
import { useNavigate } from "react-router-dom";
import { TOOL_BASE_PATH } from "tools/preferences/config";

import { useIsAuthenticated, useAuthenticatedUserInfo } from "auth/hooks/useAuth";

export default function Header({ children }) {
  const navigator = useNavigate();

  const isAuthenticated = useIsAuthenticated(navigator);
  const userInfo = useAuthenticatedUserInfo(isAuthenticated);

  return (
    <>
      <div className="dl-header">
        <div>
          <span className="text-color-reverse text-size-md" style={{ cursor: "pointer" }} onClick={() => navigator(`${TOOL_BASE_PATH.DASHBOARD}`)}>
            DashboardPage
          </span>
        </div>

        <div className="layout-header-icon text-color-reverse">
          <span style={{ marginRight: "0.5rem" }}>{userInfo.username}</span>
          <span style={{ marginRight: "0.5rem" }}>({userInfo.email})</span>
          <span style={{ marginRight: "0.5rem" }}>:</span>
          <span style={{ marginRight: "0.5rem", cursor: "pointer" }} onClick={() => navigator(`${TOOL_BASE_PATH.AUTH}/logout`)}>
            Logout ▶
          </span>
        </div>
      </div>
    </>
  );
}
