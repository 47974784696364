import { requestQuestionnaire } from "tools/api/api";
import { atom, selectorFamily } from "recoil";

export const retiredState = atom({
  key: "retiredState",
  default: null,
});

export const answersState = atom({
  key: "answerState",
  default: null,
});

export const extraValueState = atom({
  key: "extraValueState",
  default: {},
});

export const toolState = selectorFamily({
  key: "toolState/get",
  get:
    (code) =>
    async ({ get }) => {
      if (code !== undefined) {
        try {
          const tool = await requestQuestionnaire(code);
          return tool;
        } catch (error) {
          return error;
        }
      }
    },
});

export const premiumUniqueKeyState = atom({
  key: "premiumUniqueKeyState",
  default: null,
});
