import * as React from "react";
import MakeSidebarSection from "dashboards/components/sections/MakeSidebarSection";

export default function Sidebar({ children }) {
  return (
    <>
      <div className="dl-main-sidebar">
        <MakeSidebarSection />
      </div>
    </>
  );
}
