import { useQueryParams } from "commons/hooks/general";

export default function MakeNavigationSection() {
  const query = useQueryParams();
  return (
    <>
      <div className="d-nav-top">
        <span className="d-nav-m">{query.module ?? "현황"}</span> &gt; <span className="d-nav-f">{query.func ?? "응답 현황"}</span>
      </div>
    </>
  );
}
