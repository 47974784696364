import * as React from "react";
import { TOOL_BASE_PATH } from "tools/preferences/config";

export default function SearchResult({ results }) {
  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "1rem",
          marginLeft: "auto",
          border: "0.2rem solid #999999",
          marginRight: "auto",
          borderRadius: "0.5rem",
          background: "#eeeeee",
        }}
      >
        {results.map((result, index) => (
          <span key={index} style={{ cursor: "pointer" }} onClick={() => window.open(`${TOOL_BASE_PATH[result.tool.code]}/result/${result.uid}/`)}>
            <div
              
              style={{
                display: "grid",
                gridTemplateColumns: "2rem 5rem 5fr 1fr 5rem",
                columnGap: "1rem",
                paddingTop: "0.3rem",
                paddingBottom: "0.3rem",
                background: index % 2 === 0 ? "#dddddd" : "#ffffff",
              }}
            >
              <div style={{ textAlign: "center", minWidth: "2rem", maxWidth: "2rem" }}>{results.length - index}</div>
              <div style={{ textAlign: "left", minWidth: "10rem", maxWidth: "10rem" }}>
                <ResponsiveFormattedDate formattedDate={result.answered_at_formatted} />
              </div>

              <div style={{ textAlign: "left" }}>{result.tool.remark}</div>
              <div style={{ textAlign: "left" }}>{result.tool.phase}</div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "1rem" }}>결과보기</div>
            </div>
          </span>
        ))}
      </div>
    </>
  );
}

function ResponsiveFormattedDate({ formattedDate }) {
  const date = formattedDate.split(" ")[0];
  return (
    <>
      <div>{date}</div>
      {/* <div style={{ display: "flex" }}>
        <div>{date}</div>
        <div className="d-data-mobile-hidden" style={{marginLeft:"1rem"}}>{time}</div>
      </div> */}
    </>
  );
}
