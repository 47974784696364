import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box } from "@mui/material";
import { requestLogout } from "auth/api/api";
import { AUTH_TOKEN, HOST } from "commons/preferences/config";
import { localStorageGetItemWithExpiredTime, localStorageRemoveItem } from "commons/utils/localStorage";

export default function LogoutPage({ endpoint }) {
  const [loading, setLoading] = React.useState(true);
  const navigator = useNavigate();

  React.useEffect(() => {
    if (localStorageGetItemWithExpiredTime(AUTH_TOKEN) == null) {
      // navigator(`${BASE_PATH.AUTH}/login`);
      window.location.replace(`${HOST}`);
    } else {
      setLoading(false);
    }
  }, [navigator, setLoading]);

  async function handleLogout(e) {
    e.preventDefault();

    const data = await requestLogout(`Token ${localStorageGetItemWithExpiredTime(AUTH_TOKEN)}`);
    if (data) {
      localStorageRemoveItem(AUTH_TOKEN);
      // navigator(`${BASE_PATH.AUTH}/login`);
      window.location.replace(`${HOST}`);
    }
  }

  return (
    <div>
      {loading === false && (
        <Container className="container">
          <Box className="login-box">
            <h1>Are you sure you want to logout?</h1>
            <input type="button" value="Logout" onClick={handleLogout} />
          </Box>
        </Container>
      )}
    </div>
  );
}
