import * as React from "react";
import { useParams } from "react-router-dom";

import { Box, Typography, Link } from "@mui/material";

import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { toolState, answersState, extraValueState, retiredState } from "tools/atoms/atoms";
import { useScrollTop } from "commons/hooks/general";
import { requestHtmlTemplate, requestResult } from "tools/api/api";
import LoadingPage from "commons/components/LoadingPage";
import Image from "commons/components/Image";
import Programs from "tools/components/Programs";
import Divider from "tools/components/Divider";
// import SNSShares from "tools/components/SNSShare";
// import { HOST } from "commons/preferences/config";
import { DEBUG } from "commons/preferences/config";
import { TEMPLATE_BREAK, TOOL_TITLE, TOOL_PAGE_TITLE, MORE_PROGRAMS_URL } from "tools/preferences/config";
// import { TOOL_PAGE_PATH, TOOL_BASE_PATH } from "tools/preferences/config";
import StandardCharts from "tools/components/StandardCharts";
import Closing from "tools/components/Closing";
import Footer from "tools/layouts/Footer";
import Copyright from "commons/components/Copyright";

export default function ResultPage({ toolCode }) {
  const params = useParams();
  const [results, setResults] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [resultTemplate, setResultTemplate] = React.useState([]);

  // const _PATH = TOOL_BASE_PATH[toolCode];

  const HEADER_TITLE = `${TOOL_TITLE[toolCode]} ${TOOL_PAGE_TITLE["RESULT"]}`;

  const tool_config = useRecoilValue(toolState(toolCode)).tool;

  const resetExtraValueState = useResetRecoilState(extraValueState);
  const resetRetiredState = useResetRecoilState(retiredState);
  const resetAnswersState = useResetRecoilState(answersState);

  function resetState() {
    resetExtraValueState();
    resetRetiredState();
    resetAnswersState();
  }

  useScrollTop();

  React.useEffect(() => {
    async function fetchRawAnswers() {
      if (params.uid) {
        resetState();
        const analysisResults = await requestResult(params.uid);
        setResults(analysisResults);
        if (DEBUG) console.log(analysisResults);

        const templateCode = analysisResults.template_code;

        if (toolCode === "NPT_LTE") {
          const templateCodes = templateCode.split(",");

          const template1 = await requestHtmlTemplate(templateCodes[0]);
          const template2 = await requestHtmlTemplate(templateCodes[1]);

          setResultTemplate([
            { name: template1.name, cover: template1.cover, html: template1.html },
            { name: template2.name, cover: template2.cover, html: template2.html },
          ]);
          setLoading(false);
        } else {
          const template = await requestHtmlTemplate(templateCode);
          const partialTemplate = template.html.split(TEMPLATE_BREAK);

          let partials = [];

          partialTemplate.forEach((html) => {
            if (DEBUG) console.log("template.name: " + template.name);
            partials.push({
              name: template.name,
              cover: template.cover,
              html: html,
            });
          });

          setResultTemplate(partials);
          setLoading(false);
        }
      }
    }
    fetchRawAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolCode, params.uid]);

  if (loading) return <LoadingPage />;

  return (
    <>
      <Header>{HEADER_TITLE}</Header>
      <Content>
        {resultTemplate[0].cover && <Image className="result_cover_image" alt={resultTemplate[0].name} src={resultTemplate[0].cover} />}
        <Box dangerouslySetInnerHTML={{ __html: resultTemplate[0].html }} />

        {tool_config.use_driver_chart && results && <StandardCharts results={results} />}

        {resultTemplate[1] && (
          <>
            {resultTemplate[1].cover && <Image className="result_cover_image" alt={resultTemplate[1].name} src={resultTemplate[1].cover} />}
            <Box sx={{ mt: -5 }} dangerouslySetInnerHTML={{ __html: resultTemplate[1].html }} />
          </>
        )}
        {/* <SNSShares sendText={HEADER_TITLE} sendUrl={`${HOST}${_PATH}${TOOL_PAGE_PATH.RESULT}/${params.uid}/`} title={HEADER_TITLE}></SNSShares> */}

        {tool_config.use_related_programs && (
          <>
            <Divider sx={{ mt: "2rem" }}>
              <Typography variant="option" color="white">
                화담, 하다 추천 프로그램
              </Typography>
            </Divider>
            <Programs tool={toolCode} phase={results.phase} />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Link target="_blank" href={MORE_PROGRAMS_URL} color="primary.dark" underline="none">
                <Typography>더 많은 프로그램 확인하기 &gt;</Typography>
              </Link>
            </Box>
          </>
        )}
        {tool_config.use_closing_page && (
          <>
            <Closing toolCode={toolCode} />
          </>
        )}
      </Content>
      <Footer>
        <Copyright />
      </Footer>
    </>
  );
}


