import LoadingPage from "commons/components/LoadingPage";
import * as React from "react";
import ApexCharts from "react-apexcharts";

import { requestBenchmarkValue } from "tools/api/api";
import {
  getStandardDistributionChartOptions,
  getStandardDriverChartOptions,
  getStandardDivisionChartOptions,
  getStandardDistributionChartSeries,
  getStandardDriverChartSeries,
  getStandardDvisionChartSeries,
  getStandardToolChartSeries,
  getStandardToolChartOptions,
} from "tools/preferences/chartOptions";

export default function StandardCharts({ results }) {
  const [benchmarkValue, setBenchmarkValue] = React.useState(null);

  React.useEffect(() => {
    async function getBenchmarkValue() {
      const _benchmarkData = await requestBenchmarkValue(results.tool);

      if (_benchmarkData) {
        setBenchmarkValue(_benchmarkData);
      }
    }

    getBenchmarkValue();
  }, [results]);

  if (!benchmarkValue) return <LoadingPage />;

  const [distributionChartDatas, toolChartDatas, driverChartDatas, divisionChartDatas] = genChartDatas(results, benchmarkValue);

  return (
    <>
      <div className="result_paragraph result_center result_bg" style={{ marginBottom: "0rem", marginTop: "7rem" }}>결과 분석</div>
      <div className="result_paragraph">단계별 분포</div>
      <div className="chart-box-default" style={{ paddingRight: "1.65rem" }}>
        <DistributionChart datas={distributionChartDatas} />
      </div>

      <div className="result_paragraph">분석결과_1. 종합</div>
      <div className="chart-box-default" style={{ paddingRight: "1.65rem" }}>
        <ToolChart datas={toolChartDatas} />
      </div>

      <div className="result_paragraph">분석결과_2. 진단 차원별</div>
      <div className="chart-box-default">
        <DriverChart datas={driverChartDatas} toolCode={results.tool} />
      </div>
      {/* {results.tool !== "CRC_STD" && (
        <> */}
          <div className="result_paragraph">분석결과_3. 진단 항목별</div>
          <div className="chart-box-default">
            <DivisionChart datas={divisionChartDatas} />
          </div>
        {/* </>
      )} */}
      <div style={{ marginBottom: "1rem" }}>&nbsp;</div>
    </>
  );
}

function DistributionChart({ datas }) {
  const series = getStandardDistributionChartSeries(datas);
  const options = getStandardDistributionChartOptions();

  return (
    <div>
      <ApexCharts type="bar" series={series} options={options} height={130} />
    </div>
  );
}

function ToolChart({ datas }) {
  const series = getStandardToolChartSeries(datas);
  const options = getStandardToolChartOptions();

  return (
    <div>
      <ApexCharts type="bar" series={series} options={options} height={130} />
    </div>
  );
}

function DriverChart({ datas, toolCode }) {
  const series = getStandardDriverChartSeries(datas);
  const options = getStandardDriverChartOptions(toolCode);

  return (
    <div>
      <ApexCharts type="radar" series={series} options={options} />
    </div>
  );
}

function DivisionChart({ datas }) {
  return (
    <div>
      {datas.map((driver, index) => (
        <EachDriversDivisionChart key={driver.name} driver={driver} order={index + 1} last={index === datas.length - 1} />
      ))}
    </div>
  );
}

function EachDriversDivisionChart({ driver, order, last }) {
  const series = getStandardDvisionChartSeries(driver);
  const options = getStandardDivisionChartOptions(driver, order);

  console.log("last: " + last);
  const num_divisions = Math.max(3, series[0].data.length)
  // console.log("num_divisions: " + num_divisions);
  const height = Math.max(num_divisions * 70, 160)

  return (
    <>
      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <ApexCharts type="bar" series={series} options={options} height={height} />
      </div>
      {!last && (
        <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
          <hr style={{ border: "2px solid #eeeeee", width: "50%" }} />
        </div>
      )}
    </>
  );
}

function extractChartData(results, benchmarkData) {
  const driverArray = results?.evaluated?.drivers;

  if (!driverArray) return null;

  function normalizer(moderated_value, value){
    if(moderated_value){
      return moderated_value.toFixed(2)
    }
    else {
      return (value).toFixed(2)
    }
  }

  return [
    benchmarkData.tool,
    {
      value: normalizer(parseFloat(results?.evaluated?.moderated_value), parseFloat(results?.evaluated?.value)),
      bmValue: benchmarkData.tool.benchmark,
      avgValue: benchmarkData.tool.average,
    },
    driverArray.map((driver) => ({
      code: driver.code,
      name: driver.name,
      name_en: driver.name_en,
      desc: driver.desc,
      order: driver.order,
      weight: driver.weight,
      value: driver.value,
      moderated_value: normalizer(parseFloat(driver.moderated_value), parseFloat(driver.value)),
      bmValue: benchmarkData.drivers.benchmark[driver.code],
      avgValue: benchmarkData.drivers.average[driver.code],

      divisions: driver.divisions.map((division) => ({
        code: division.code,
        name: division.name,
        weight: division.weight,
        value: division.value,
        moderated_value: normalizer(parseFloat(division.moderated_value), parseFloat(division.value)),
        bmValue: benchmarkData.divisions.benchmark[division.code],
        avgValue: benchmarkData.divisions.average[division.code],
      })),
    })),
  ];
}

function genChartDatas(results, benchmarkData) {
  const [distribution, tool, data] = extractChartData(results, benchmarkData);

  const distributionChartDatas = distribution;
  const toolChartDatas = tool;
  const driverChartDatas = data;
  const divisionChartDatas = data;

  return [distributionChartDatas, toolChartDatas, driverChartDatas, divisionChartDatas];
}
