import * as React from "react";

import { useNavigate } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { toolState, answersState, extraValueState, retiredState, premiumUniqueKeyState } from "tools/atoms/atoms";
import { responseAnswers } from "tools/api/api";

import { Box } from "@mui/material";

import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";

import LoadingPage from "commons/components/LoadingPage";
import { isEmpty } from "commons/utils/helpers";

import { useFormPage } from "tools/hooks/useForms";
import { useSetExtraValue } from "tools/hooks/general";
import { DEBUG } from "commons/preferences/config";
import { TOOL_TITLE, TOOL_BASE_PATH, TOOL_PAGE_TITLE } from "tools/preferences/config";
import { postprocess } from "tools/api/postprocess";
import Copyright from "commons/components/Copyright";
import { useScrollTop, usePreventBack, usePreventRefresh } from "commons/hooks/general";
import Footer from "tools/layouts/Footer";

export default function ProfilePage({ toolCode, formSegment, nextPage }) {
  usePreventRefresh();
  usePreventBack();

  const navigator = useNavigate();
  const tools = useRecoilValue(toolState(toolCode));
  const answers = useRecoilValue(answersState);
  const extras = useRecoilValue(extraValueState);
  const retired = useRecoilValue(retiredState);
  const uniqueKey = useRecoilValue(premiumUniqueKeyState);
  const uniqueKeyQuery = uniqueKey ? `/?uniqueKey=${uniqueKey}` : "";
  const formConfig = tools?.[formSegment] || [];
  useSetExtraValue("timestamp_page", "profile_page");

  const [forms, alertDialog, formValidate] = useFormPage(formSegment, formConfig);

  async function handleClickNext() {
    if (formValidate()) {
      const newAnswers = {
        ...answers,
        retired: retired,
        uniqueKey: uniqueKey,
      };

      const data = postprocess(toolCode, newAnswers, extras);
      const uid = await responseAnswers(data);
      if (uid) {
        if (DEBUG) {
          console.log(JSON.stringify("uid: " + uid));
        }

        navigator(`${nextPage}${uid}${uniqueKeyQuery}`);
      } else {
        navigator(`${TOOL_BASE_PATH.ERROR}`);
      }
    }
  }
  useScrollTop();
  if (isEmpty(formConfig)) return <LoadingPage />;

  return (
    <>
      <Header>{`${TOOL_TITLE[toolCode]}  ${TOOL_PAGE_TITLE["PROFILE"]}`}</Header>
      <Content>
        {forms}
        <Box sx={{ mt: 5, mb: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          <button className="common-button" onClick={handleClickNext}>
            확인
          </button>
        </Box>
      </Content>
      <Footer>
        <Copyright />
      </Footer>
      {alertDialog}
    </>
  );
}
