import * as React from "react";
import { useNavigate } from "react-router-dom";
// import { Box, Divider } from "@mui/material";

import Content from "tools/layouts/Content";
import Header from "tools/layouts/Header";
import Footer from "tools/layouts/Footer";
import Copyright from "commons/components/Copyright";

import { TOOL_BASE_PATH, TOOL_TITLE } from "tools/preferences/config";

export default function ToolsPage() {
  const navigator = useNavigate();

  return (
    <>
      <Header>{`${TOOL_TITLE["TOOL_PAGE"]}`}</Header>
      <Content>
        <div className="page_container div-center">
          <div className="page_headline page_center">화담,하다 개인 & 기업 진단 솔루션</div>
          <div className="tool-page-wrapper">
            <div className="tool-page-tool-wrapper">
              <div className="tool-page-tool-abbr">Re:PLAT</div>
              <div className="tool-page-tool-name">개인용 퇴직 솔루션</div>
              <div className="tool-page-tool-en">For Person</div>
              <div className="tool-page-tool-phrase">개인 맞춤형 퇴직준비 및 실행 솔루션</div>
              <div className="tool-page-tool-desc">
                RRI · NPT
                <br />
                두 가지 진단 도구로 구성되어 있으며,
                <br />
                진단 결과를 기반으로 개인화된 퇴직 실행 프로그램을 제안합니다.
              </div>
              <div className="tool-page-button-wrapper">
                <button className="tool-page-button-tool" onClick={() => navigator(TOOL_BASE_PATH.REPLAT)}>
                  진단하기
                </button>
                <button className="tool-page-button-explain" onClick={() => window.open("https://www.whadam.co/replat")}>
                  자세히 보기
                </button>
              </div>
            </div>

            <div className="tool-page-tool-wrapper">
              <div className="tool-page-tool-abbr">S-Cockpit</div>
              <div className="tool-page-tool-name">기업용 전략 솔루션</div>
              <div className="tool-page-tool-en">For Company</div>
              <div className="tool-page-tool-phrase">기업 맞춤형 전략 실행 솔루션</div>
              <div className="tool-page-tool-desc">
                PMA · CRC · RPS
                <br />
                세 가지 진단 도구로 구성되어 있으며,
                <br />
                진단 결과를 기반으로 기업 맞춤형 실행 전략을 제안합니다.
                <br />
              </div>
              <div className="tool-page-button-wrapper">
                <button className="tool-page-button-tool" onClick={() => navigator(TOOL_BASE_PATH.SCOCKPIT)}>
                  진단하기
                </button>
                <button className="tool-page-button-explain" onClick={() => window.open("https://www.whadam.co/biz")}>
                  자세히 보기
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <Footer>
        <Copyright />
      </Footer>
    </>
  );
}
