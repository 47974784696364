import * as React from "react";

import { useRecoilState } from "recoil";

import { extraValueState } from "tools/atoms/atoms";

import { getTimestamp } from "commons/utils/helpers";
import {DEBUG} from "commons/preferences/config";
import * as CONFIG from "tools/preferences/config";

import { requestHtmlTemplate } from "tools/api/api";


export function useSetExtraValue(segment, key, value = null) {
  const [extraValues, setExtraValue] = useRecoilState(extraValueState);

  if (value === null) {
    value = getTimestamp();
  }

  const changedSegment = {
    ...extraValues[segment],
    [key]: value,
  };

  React.useEffect(() => {
    const changedExtraValues = {
      ...extraValues,
      [segment]: changedSegment,
    };

    setExtraValue(changedExtraValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment, key]);

  React.useEffect(() => {
    if (DEBUG) console.log(JSON.stringify(extraValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment, key, value]);
}

export function useRequestTemplate(templateCode) {
  const [loading, setLoading] = React.useState(true);
  const [template, setTemplate] = React.useState([]);

  React.useEffect(() => {
    async function fetchRawAnswers() {
      const _template = await requestHtmlTemplate(templateCode);
      const partialTemplate = _template.html.split(CONFIG.TEMPLATE_BREAK);
      setTemplate(partialTemplate);
      setLoading(false);
    }
    fetchRawAnswers();
  }, [templateCode]);

  return [loading, template];
}

