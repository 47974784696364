import * as React from "react";
import { useNavigate } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import SearchIcon from "@mui/icons-material/Search";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import AdjustIcon from "@mui/icons-material/Adjust";

import { useIsAuthenticated } from "auth/hooks/useAuth";

import { HOST } from "commons/preferences/config";
import * as PREFERENCE from "tools/preferences/config";
import * as STYLE from "commons/preferences/styles";

import HeaderTitle from "tools/components/HeaderTitle";

export default function Header({ children, sx }) {
  const navigator = useNavigate();
  const isAuthenticated = useIsAuthenticated(navigator);

  function handleClickHome() {
    window.location.replace(`${HOST}`);
  }

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };
  React.useEffect(() => {
    window.addEventListener("scroll", updateScroll);
  });

  return (
    <>
      <div className={scrollPosition < 1 ? "layout-header-fixed-wrapper" : "layout-header-fixed-wrapper-shaded"}>
        <div className="layout-header">
          <div className="layout-header-icon">
            {/* <HomeIcon sx={{ ...STYLE.iconSize }} onClick={handleClickHome} /> */}
            <div>
              <img
                style={{ cursor: "pointer" }}
                alt="whadam_logo"
                src="https://tools.whadam.co/media/mail/whadam_logo.png"
                width="40px"
                onClick={handleClickHome}
              />
            </div>
          </div>
          <div className="layout-header-icon">
            <AdjustIcon sx={{ fontSize: "0.5rem", color: "#FFFFFF" }} />
          </div>
          <div className="layout-header-title">
            <HeaderTitle text={children} />
          </div>

          {isAuthenticated ? (
            <>
              <div className="layout-header-icon">
                <DashboardIcon sx={{ ...STYLE.iconSize }} onClick={() => navigator(`${PREFERENCE.TOOL_BASE_PATH.DASHBOARD}`)} />
              </div>
              <div className="layout-header-icon">
                <LogoutIcon sx={{ ...STYLE.iconSize }} onClick={() => navigator(`${PREFERENCE.TOOL_BASE_PATH.AUTH}/logout`)} />
              </div>
            </>
          ) : (
            <>
              <div className="layout-header-icon">
                <SearchIcon sx={{ ...STYLE.iconSize }} onClick={() => navigator(PREFERENCE.TOOL_BASE_PATH.SEARCH)} />
              </div>
              <div className="layout-header-icon">
                <LoginIcon sx={{ ...STYLE.iconSize }} onClick={() => navigator(PREFERENCE.TOOL_BASE_PATH.AUTH)} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
