import * as React from "react";
import { Routes, Route } from "react-router-dom";

import LoginPage from "auth/LoginPage";
import LogoutPage from "auth/LogoutPage";
import ResetPage from "auth/ResetPage";

export default function Router({ endpoint }) {
  return (
    <>
      <Routes>
        <Route path="" element={<LoginPage endpoint={endpoint} />} />
        <Route path="login" element={<LoginPage endpoint={endpoint} />} />
        <Route path="logout" element={<LogoutPage endpoint={endpoint} />} />
        <Route path="reset" element={<ResetPage />} />
        <Route path="*" element={<LoginPage endpoint={endpoint} />} />
      </Routes>
    </>
  );
}
