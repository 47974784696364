import * as React from "react";

import { useFetchListData } from "dashboards/hooks/useFetchListData";
import { DASHBOARD_API_URL, DASHBOARD_PATH } from "dashboards/preferences/config";
import { RESULTS_LIST } from "dashboards/preferences/tables";
import { makeQueryString } from "dashboards/utils/makeQuery";
import { settingHeaderAndRows } from "dashboards/utils/settingTable";
import { useQueryParams } from "commons/hooks/general";

import MakeTable from "dashboards/components/makeTable/MakeTable";
import MakePagination from "dashboards/components/makeTable/MakePagination";

import MakeNavigationSection from "dashboards/components/sections/MakeNavigationSection";
import MakeTitleSection from "dashboards/components/sections/MakeTitleSection";
import MakeSearchSection from "dashboards/components/sections/MakeSearchSection";

export default function ResultsListPage() {
  const query = useQueryParams();

  const queryString = makeQueryString(query);

  const datas = useFetchListData(DASHBOARD_API_URL.RESULTS_LIST, queryString);


  
  const [header, rows] = settingHeaderAndRows(datas, RESULTS_LIST);

  return (
    <>
      {datas && (
        <>
          <MakeNavigationSection />
          <MakeTitleSection />
          <MakeSearchSection action="/status/results" />
          <MakeTable header={header} rows={rows} detail={DASHBOARD_PATH.RESULT_DETAIL} />
          <MakePagination datas={datas} />
        </>
      )}
    </>
  );
}

